<template>
<flipCard ref="flipCard" v-bind:flipped="cardFlipped">
<template slot="front">
  <v-card
    outlined
    tile
    shaped
    rounded-lg
    :ripple="false"
    :style="{ backgroundColor: back_color, height: '280px', backgroundColor: '#0B0815' }"
   
  >
  <div v-on:click="flipCard()" v-if="back_table" style="position: absolute; right: 10px; top: 15px; cursor: pointer;">
      <img class="flipCard" style="width: 28px; height: 28px;" :src="cardImage" />
    </div>
    <v-card-title>{{ title }} </v-card-title>
    <v-card-subtitle style="margin-top: -20px; text-align: left">{{
      subtitle
    }}</v-card-subtitle>

    <v-card-text style="height: 210px;">          
      <div style="margin-top: -2px; margin-bottom: 30px" v-if="data_table">
        <div v-for="(o, idx) in Object.keys(data_table)" :key="o">
          <div
            style="
              display: flex;
              width: 100%;
              font-size: 9pt;
              border: 0px solid yellow;
            "
          >
            <div
              style="
                font-weight: normal;
                color: white;
                text-align: left;
                width: 60%;
              "
            >
              <span v-html="o"></span>
            </div>
            <div style="text-align: right; width: 70%">
              <div v-if="number_colors && data_table[o].includes('%')">
                <span v-if="parseFloat(data_table[o].replace('$','').replace('%','')) < 0" style="color: #e6686e"
                  >{{
                  data_table[o]
                  }}</span
                >
                <span v-if="parseFloat(data_table[o].replace('$','').replace('%','')) >= 0" style="color: #21f07e"
                  >{{
                  data_table[o]
                  }}</span
                >
              </div>
              <div v-else>
                {{ data_table[o] }}
              </div>
            </div>
          </div>

          <div
            v-if="idx < Object.keys(data_table).length - 1"
            style="margin-bottom: 5px"
          >
            <v-divider />
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<template slot="back">
  <v-card
    outlined
    tile
    shaped
    rounded-lg
    :ripple="false"
    :style="{ backgroundColor: back_color, height: '280px', backgroundColor: '#0B0815' }"
  >
  <div v-on:click="flipCard()" v-if="back_table" style="position: absolute; right: 10px; top: 15px; cursor: pointer;">
      <img class="flipCard" style="width: 28px; height: 28px;" :src="cardImage" />
    </div>
    <v-card-title>{{ titleBack }} </v-card-title>
    <v-card-subtitle style="margin-top: -20px; text-align: left">{{
      subtitleBack
    }}</v-card-subtitle>

    <v-card-text style="margin: 0px; padding: 5px;">     
        <v-simple-table id="simple_table" dense style="background-color: transparent; font-size: 9pt !important; margin-top: -20px;">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-center">
            Rank
          </th>
          <th class="text-left">
            Miner
          </th>
          <th class="text-right">
            Hashrate
          </th>
          <!--<th v-for="h in backTableHeaders" :key="h" class="text-left" style="font-size: 9pt !important;">
            {{ h }}
          </th> -->
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item, idx in back_table"
          :key="item.miner"
          style="font-size: 9pt !important;"
        >
          <td v-if="idx == 0" class="text-center" style="font-size: 9pt !important;"><img style="margin-top: 5px; width: 22px;" src="1st.png"></td>
          <td v-if="idx == 1" class="text-center" style="font-size: 9pt !important;"><img style="margin-top: 5px; width: 22px;" src="2nd.png"></td>
          <td v-if="idx == 2" class="text-center" style="font-size: 9pt !important;"><img style="margin-top: 5px; width: 22px;" src="3rd.png"></td>
          <td v-if="idx>2" class="text-center" style="font-size: 9pt !important;">{{ idx + 1 }}</td>
          <td class="text-left" style="font-size: 9pt !important;">{{ item.miner }}</td>
          <td class="text-right" style="font-size: 9pt !important;">{{ item.hashrate }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>     
    </v-card-text>
  </v-card>
</template>
</flipCard>
</template>


<style scoped>

.glass {
  background: rgba(35, 9, 66, 1) !important;
  /*box-shadow: 0 4px 30px rgba(0, 0, 0, 1) !important;*/
  /*backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;*/
}

#simple_table >>> tr:hover {
  background: transparent !important;
  cursor: default;
}

.flipCard {
  opacity: 0.7;
}
.flipCard:hover {
  opacity: 1;
}

</style>
<script>
import flipCard from './flipCard.vue';

export default {
  name: "DetailsCard",
  components: {
    flipCard
  },
  props: {
    title: undefined,
    subtitle: undefined,   
    titleBack: undefined,
    subtitleBack: undefined, 
    data_table: {},
    back_color: {
      default: "#FFEA80",
    },    
    number_colors: {
      default: false
    },   
    back_table: {
      default: undefined
    },
    cardImage: {
      default: undefined
    }
  },
  watch: {  
    back_table: {
      handler() {
        this.generateBackTable();
      }
    }
  },
  data: () => ({
    backTableHeaders: [],
    cardFlipped: false 
  }),
  created() {
    let ls = localStorage.getItem("detailsFlipCard");
    //console.info("Retrieved Flipped: " + ls);
    if (ls === 'yes') this.cardFlipped = true;
    else this.cardFlipped = false;    
  },
  mounted() {   
    this.generateBackTable();     
  },
  methods: {
    flipCard() {
      this.cardFlipped = !this.cardFlipped;

      if (this.cardFlipped) {
        localStorage.setItem("detailsFlipCard", "yes");  
      }
      else localStorage.setItem("detailsFlipCard", "no");               
    },
    generateBackTable() {
      //console.log("Generating back table...");
      this.backTableHeaders = Object.keys(this.back_table[0]);
      let i = 0;
      this.backTableHeaders.forEach( h => {
        this.backTableHeaders[i] = h.charAt(0).toUpperCase() + h.slice(1);
        i++;
      });
    }
  
  },
};
</script>
