<template>
  <v-card
    outlined
    tile
    shaped
    rounded-lg
    class="glass"
    style="{ background-color: : #2F2F2F; max-width: 1310px; }"
  >
    <v-card-title style="width: 100%"
      >Configure your Miner<v-spacer></v-spacer
      ><span
        style="display: none; text-align: right; margin-top: 0px"
        class="fi fi-rr-pickaxe"
      ></span
    ></v-card-title>
    <v-card-subtitle style="text-align: left"
      >Get Ready to Mine with ThunderHash</v-card-subtitle
    >
    <v-card-text
      style="display: flex; flex-direction: column; justify-content: justify"
    >
      <p class="pth">
        If you have already configured your miner to mine with ThunderHash,
        please, enter your Zephyr address in the textbox at the bottom of this
        page. Otherwise, you can continue with the miner setup and ThunderHash
        pool configuration.
      </p>

      <h2
        style="
          text-align: left;
          color: lightgray;
          margin-top: 20px;
          margin-bottom: 5px;
        "
      >
        Zephyr Protocol Wallet
      </h2>
      <p class="pth">
        If you don't already have a Zephyr Protocol wallet, you can create one
        by downloading the wallet software from
        <a href="https://zephyrprotocol.com" target="_blank"
          >zephyrprotocol.com</a
        >
        or using the online wallet.
      </p>
      <v-btn
        class="ma-2 white--text"
        style="
          margin: -5px;
          display: block;
          width: 250px;
          margin-top: 20px;
          margin-bottom: 20px;
        "
        @click="openLink('https://wallet.zephyrprotocol.com')"
      >
        <img
          style="margin-top: 1px; margin-right: 5px; height: 18px; width: 18px"
          src="zephyr-logo.png"
        />Zephyr Online Wallet
      </v-btn>
      <h2
        style="
          text-align: left;
          color: lightgray;
          margin-top: 20px;
          margin-bottom: 5px;
        "
      >
        Mining software and pool configuration
      </h2>
      <h3
        style="
          text-align: left;
          color: lightgray;
          margin-top: 20px;
          margin-bottom: 5px;
        "
      >
        XMRig
      </h3>
      <div style="display: flex; flex-direction: column">
        <p class="pth">
          If you want to mine yourself, you can use XMRig. XMRig is a high
          performance, open source, cross platform RandomX, KawPow, CryptoNight
          and GhostRider unified CPU/GPU miner and RandomX benchmark. Official
          binaries are available for Windows, Linux, macOS and FreeBSD. Click on
          the button below for instructions on how to download and setup. If you
          want to see how your hardware will perform in terms of hashes per
          second, you can check and look for your computer processor in
          <a href="https://xmrig.com/benchmark" target="_blank"
            >XMRig Benchmark</a
          >
        </p>
        <div
          style="
            display: flex;
            margin-top: 0px;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            margin-bottom: 20px;
            border: 1px solid gray;
            padding: 10px;
          "
        >
          <div style="margin-top: 0px; margin-right: 8px">
            <img style="width: 42px" src="warning-2.png" />
          </div>
          <div style="text-align: justify">
            Some Windows antivirus may detect XMRig as a trojan but XMRig is a
            <a
              href="https://www.pcrisk.com/removal-guides/12964-xmrig-virus"
              target="_blank"
              >completely legitimate open-source application</a
            >
            that utilizes system CPUs to mine different cryptocurrencies.
            Unfortunately, criminals generate revenue by infiltrating this app
            into systems without users' consent hence some antivirus treat it
            like a trojan when it is <strong>completely safe.</strong>
          </div>
        </div>
        <v-btn
          class="ma-2 white--text"
          style="margin: -5px; margin-top: 30px; display: block; width: 250px"
          @click="openLink('https://xmrig.com')"
        >
          <img
            style="
              margin-top: 1px;
              margin-right: 5px;
              height: 18px;
              width: 18px;
            "
            src="xmrig-logo.svg"
          />Download XMRig
        </v-btn>
        <p class="pth" style="margin-top: 10px">
          Once you have downloaded XMRig for your operating system you can run
          it by executing the program in the command line/terminal by replacing
          the field YOUR_WALLET_ADDRESS with the address you wish to receive the
          reward to and optionally, YOUR_WORKER_NAME if you plan to use more
          than one machine to mine.
        </p>
        <h4 style="text-align: left; margin-top: 5px">Linux / macOS</h4>
        <div
          style="
            background-color: black;
            font-family: monospace;
            padding: 20px;
            margin: 0px;
            text-align: left;
          "
        >
          ./xmrig -o zeph.thunderhash.com:5555 -u YOUR_WALLET_ADDRESS -p
          YOUR_WORKER_NAME -k -a rx/0
        </div>
        <h4 style="text-align: left; margin-top: 10px">Windows</h4>
        <div
          style="
            background-color: black;
            font-family: monospace;
            padding: 20px;
            margin: 0px;
            text-align: left;
          "
        >
          xmrig.exe -o zeph.thunderhash.com:5555 -u YOUR_WALLET_ADDRESS -p
          YOUR_WORKER_NAME -k -a rx/0
        </div>

       
          <div style="display: flex; justify-content: start; margin-top: 10px;">         
            <div style="text-align: left">
              <img style="width: 22px; margin-right: 8px; margin-top: -1px; float: left;" src="info.png" />
              For <strong>SOLO</strong> mining you just need to add
              <strong>solo:</strong> preffix to your miner address in the
              configuration above:
              <span style="font-family: monospace; font-size: 9pt"
                >solo:YOUR_WALLET_ADDRESS</span
              >
            </div>
          </div>
          <div style="display: flex; justify-content: start; margin-top: 10px;">          
            <div style="text-align: left;">
              <img style="width: 22px; margin-right: 8px; margin-top: 2px; float: left;" src="tor-logo.png" />
              For configuring XMRig to use Tor, please
              install Tor Browser in your machine and follow the instructions at
              <a href="https://xmrig.com/docs/miner/tor" target="_blank"
                >Tor & SOCKS5</a
              >.
              To use ThunderHash through Onion you need to point your miner to
              <span style="font-family: monospace; font-size: 6pt;"
                >ewqzjeww73x4ocnuo7owmerpgzlzzcgxoyak56mci5mbiqqyvj5t5bad.onion</span
              >
              on the TLS port 9999. 
            </div>
          </div>
   

        <h3
          style="
            text-align: left;
            color: lightgray;
            margin-top: 20px;
            margin-bottom: 5px;
          "
        >
          Cloud Mining / NiceHash
        </h3>
        <p class="pth">
          Cloud mining makes cryptocurrency mining accessible without the need
          for heavy hardware investments or technical expertise. With cloud
          mining you can avoid upfront costs and ongoing maintenance expenses by
          renting hashing power instead of purchasing and maintaining mining
          hardware. ThunderHash supports and recommends mining with cloud
          services like Nicehash. Open an account at NiceHash and follow their
          guides.
        </p>
        <v-btn
          class="ma-2 white--text"
          style="width: 250px; margin-bottom: 30px"
          @click="openLink('https://nicehash.com')"
        >
          <img
            style="
              margin-top: 1px;
              margin-right: 5px;
              height: 18px;
              width: 18px;
            "
            src="logo_nh.png"
          />Visit NiceHash
        </v-btn>
        <h4 style="text-align: left; margin-top: 10px; margin-bottom: 5px">
          NiceHash Pool Configuration and Test
        </h4>
        <p class="pth">
          You can configure and test a NiceHash pool for ThunderHash as shown in
          the following screenshots. Remember to use port 6666 has it has the
          difficulty adjusted for NiceHash.
        </p>
        <div
          style="
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: left;
            gap: 20px;
          "
        >
          <img style="width: 320px" src="nh_pool_config.png" />
          <img style="width: 320px" src="nh_pool_test.png" />
        </div>

        <h3 style="text-align: left; color: lightgray; margin-top: 20px">
          Setup your miner Zephyr Protocol Address in ThunderHash
        </h3>
        <p class="pth">
          In order for ThunderHash to show you the statistics of your miner, you
          need to fill in your miner Zephyr address. This will be saved in your
          browser local storage so you don't have to enter it again for this
          browser.
        </p>

        <v-text-field
          v-model="inputMinerAddress"
          label="Miner Address"
          placeholder="Enter your miner address"
          outlined
          hint="A valid Zephyr protocol address (ZEPHYR....LPLVJL)"
          clearable
          :error="inputMinerAddressError"
          @input="inputMinerAddressError = false"
          :error-messages="inputMinerAddressErrorMsg"
          :success-messages="inputMinerAddressSuccessMsg"
        ></v-text-field>
        <v-btn block elevation="2" @click="setMinerAddress"
          >Set Miner Address</v-btn
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.pth {
  text-align: left;
  color: lightgray;
  text-align: justify;
}
</style>

<script>
export default {
  name: "MinerSetupCard",
  computed: {},
  props: {
    apiUrl: undefined,
  },
  data: () => ({
    inputMinerAddress: "",
    inputMinerAddressError: false,
    inputMinerAddressErrorMsg: "",
    inputMinerAddressSuccessMsg: "",
  }),
  mounted() {
    if (this.$router.app.$children[0]._data.minerAddress) {
      this.inputMinerAddress = this.$router.app.$children[0]._data.minerAddress;
    }
  },
  methods: {
    async setMinerAddress() {
      //console.log(this.inputMinerAddress);

      if (!this.inputMinerAddress.startsWith("ZEPH")) {
        this.inputMinerAddressErrorMsg = "Invalid address format";
        this.inputMinerAddressError = true;
        return;
      }
      if (this.inputMinerAddress.trim().length != 101) {
        this.inputMinerAddressErrorMsg = "Invalid address length";
        this.inputMinerAddressError = true;
        return;
      }

      let foundAddress = await this.checkAddress(this.inputMinerAddress);

      if (!foundAddress) {
        this.inputMinerAddressErrorMsg =
          "Address not found at ThunderHash. Connect your miner first and/or wait for it to submit a valid share.";
        this.inputMinerAddressError = true;
        return;
      }

      this.inputMinerAddressError = false;
      localStorage.setItem("minerAddress", this.inputMinerAddress);
      this.$emit("updated-address", this.inputMinerAddress);
      this.inputMinerAddressErrorMsg = "";
      this.inputMinerAddressSuccessMsg = "Address successfully updated.";
    },
    async checkAddress(addr) {
      return new Promise((resolve, reject) => {
        var url = this.apiUrl + "stats_address";
        url = url + "?address=" + addr;
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (data.error) resolve(false);
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject(false);
          });
      });
    },
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
