<template>
  <v-app>
    <v-navigation-drawer id="drawerOpenIcon" v-model="drawer" v-click-outside="hideDrawer" fixed floating clipped left style="background-color: black; top: 48px; border: 0px solid black !important;">
      <v-list nav dense style="border-top: 0px solid rgb(40, 39, 39);">
        <v-list-item-group active-class="deep-purple--text text--accent-4" style="border: 0px solid black;">
          <v-list-item to="/">
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item to="setup-miner">
            <v-list-item-title>Setup Miner</v-list-item-title>
          </v-list-item>
          <v-list-item to="faq">
            <v-list-item-title>FAQ</v-list-item-title>
          </v-list-item>       
          <!--
          <v-divider style="margin: 10px;"></v-divider>
          <v-list-item to="poolblocks">
            <v-list-item-title>Pool Blocks</v-list-item-title>
          </v-list-item>
          <v-list-item to="poolpayments">
            <v-list-item-title>Pool Payments</v-list-item-title>
          </v-list-item>
          -->
          <v-divider style="margin: 10px;"></v-divider>
          <v-list-item to="nonce-dist">
            <v-list-item-title>Nonce Distribution</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar 
    clipped-left
    app static color="black" dense>
      <v-app-bar-nav-icon id="drawerOpenIcon" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <img class="logo" @click.stop="drawer = !drawer" style="margin-left: -12px; height: 56px; width: 56px" src="logoth.png" />

      <v-toolbar-title style="margin-left: -10px; margin-top: 2px"
        ><span style="font-weight: normal; opacity: 1;">Thunder</span
        ><span style="font-weight: bold">Hash</span></v-toolbar-title
      >

      <v-spacer></v-spacer>
      <!-- {{ minerAddress }} -->
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid style="max-width: 1620px; margin-top: 10px">
        <!-- If using vue-router -->
        <router-view @hide-drawer="drawer=false" @updated-address="updateMinerAddress" style="width: 100%;"></router-view>
      </v-container>
    </v-main>

    <v-footer
      app
      style="
        padding: 10px;
        padding-bottom: 6px;
        padding-top: 6px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: black;
        border-top: 1px solid rgb(17, 17, 17);
        opacity: 0.85;
      "
    >
   
    <div
    
        style="
          border-bottom: 1px solid rgb(19, 28, 28);
          display: flex;
          justify-content: center;
          gap: 40px;
          margin-bottom: 20px;
          padding-bottom: 18px;
          align-items: center;
          width: 100%;
          display: none;
        "
           
        class="hidden-sm-and-down"
      >
        <div
          class="linkDiv"
          style="display: flex; flex-direction: row"
          @click="openLink('https://t.me/thunderhash')"
        >
          <img
            style="
              margin-top: 1px;
              margin-right: 5px;
              height: 18px;
              width: 18px;
            "
            src="spain_flag.png"
          />
       
        <div class="hidden-sm-and-down" style="font-size: 11pt">
          Hablamos Español
        </div>
      </div>
      <div
          class="linkDiv"
          style="display: flex; flex-direction: row"
          @click="openLink('https://t.me/thunderhash')"
        >
          <img
            style="
              margin-top: 1px;
              margin-right: 5px;
              height: 18px;
              width: 18px;
            "
            src="usa_flag.png"
          />
       
        <div class="hidden-sm-and-down" style="font-size: 11pt">
          We speak English
        </div>
      </div>
      <div
          class="linkDiv"
          style="display: flex; flex-direction: row"
          @click="openLink('https://t.me/thunderhash')"
        >
          <img
            style="
              margin-top: 1px;
              margin-right: 5px;
              height: 18px;
              width: 18px;
            "
            src="romania_flag.png"
          />
       
        <div class="hidden-sm-and-down" style="font-size: 11pt">
          Vorbim Română
        </div>
      </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 100%;
          margin-top: 0px;
          margin-bottom: 6px;
          padding-top: 6px;
        "
      >
     

        <div
          class="linkDiv"
          style="display: flex; flex-direction: row"
          @click="openLink('https://zephyrprotocol.com')"
        >
          <img
            style="
              margin-top: 1px;
              margin-right: 5px;
              height: 18px;
              width: 18px;
            "
            src="zephyr-logo.png"
          />
          <div class="hidden-sm-and-down" style="font-size: 11pt">Zephyr</div>
        </div>

        <div
          class="linkDiv"
          style="display: flex; flex-direction: row"
          @click="openLink('https://xmrig.com')"
        >
          <img
            style="
              margin-top: 1px;
              margin-right: 5px;
              height: 18px;
              width: 18px;
            "
            src="xmrig-logo.svg"
          />
          <div class="hidden-sm-and-down" style="font-size: 11pt">XMRig</div>
        </div>

        <div
          class="linkDiv"
          style="display: flex; flex-direction: row"
          @click="openLink('https://nicehash.com')"
        >
          <img
            style="
              margin-top: 1px;
              margin-right: 5px;
              height: 18px;
              width: 18px;
            "
            src="logo_nh.png"
          />
          <div class="hidden-sm-and-down" style="font-size: 11pt">Nicehash</div>
        </div>

        <div
          class="linkDiv"
          style="display: flex; flex-direction: row"
          @click="openLink('https://www.miningrigrentals.com/register?ref=2712163')"
        >
          <img
            style="
              margin-top: 1px;
              margin-right: 5px;
              height: 20px;
              width: 26px;
            "
            src="mrr.png"
          />
          <div class="hidden-sm-and-down" style="font-size: 11pt">MiningRigRentals</div>
        </div>

        <!--<div
          class="linkDiv"
          style="display: flex; flex-direction: row"
          @click="openLink('https://t.me/thunderhash')"
        >
          <img
            style="
              margin-top: 1px;
              margin-right: 5px;
              height: 18px;
              width: 18px;
            "
            src="logo_telegram.png"
          />
          <div class="hidden-sm-and-down" style="font-size: 11pt">Telegram</div>
        </div>-->

        <div
          class="linkDiv"
          style="display: flex; flex-direction: row"
          @click="openLink('https://discord.gg/YargZ9Xc6Y')"
        >
          <img
            style="
              margin-top: 1px;
              margin-right: 5px;
              height: 18px;
            "
            src="discord-mark-white.png"
          />
          <div class="hidden-sm-and-down" style="font-size: 11pt">Discord</div>
        </div>

        <div
          class="linkDiv"
          style="display: flex; flex-direction: row"
          @click="openLink('https://x.com/thunderhashcom')"
        >
          <img
            style="
              margin-top: 2px;
              margin-right: 5px;
              height: 16px;
              width: 16px;
            "
            src="logo-x.png"
          />
          <div class="hidden-sm-and-down" style="font-size: 11pt">
            Twitter
          </div>
        </div>
        <div
          class="linkDiv"
          style="display: flex; flex-direction: row"
          @click="openLink('http://w3uikbh35z35kqickxvt6ad3vepexhlobomfzqy2ybpmna7cht7iuuad.onion/')"
        >
          <img
            style="
              margin-top: -2px;
              margin-right: 5px;    
              height: 18px;       
            "
            src="tor-logo.png"
          />
          <div class="hidden-sm-and-down" style="font-size: 11pt">Onion</div>
        </div>
      </div>
     
      <!--<div style="margin-top: 15px; font-size: 9pt;">&copy; 2023 ThunderHash Mining Services</div>-->
    </v-footer>
  </v-app>
</template>

<style lang="scss">
.logo-test:hover{
      -webkit-animation: spin 2s infinite;
       animation: spin 2s infinite;
    }
    
    @-webkit-keyframes spin{
      from {
        -webkit-transform: rotateY(0deg);
      }
      to {
        -webkit-transform: rotateY(-360deg);
      }
    }
    
    @keyframes spin{
      from {
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
      }
      
      to {
        -moz-transform: rotateY(-360deg);
        -ms-transform: rotateY(-360deg);
        transform: rotateY(-360deg);
      }
    }
/*.v-toolbar--collapse {
  opacity: 1;  
    transition: opacity 1s ease-out; 
    -webkit-transition: opacity 1s ease-out;  
    -moz-transition: opacity 1s ease-in-out;    

}

.v-toolbar__title {
  display: block !important;
}

.v-toolbar--collapsed {
  min-width: 100% !important;
}
.v-app-bar--is-scrolled {
    max-width: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
    opacity: 0;  
    transition: opacity 1s ease-out; 
    -webkit-transition: opacity 1s ease-out;  
    -moz-transition: opacity 1s ease-in-out;      
}*/
html {
  scroll-behavior: smooth;
}

.glass {
  background: rgba(35, 9, 66, 0.19) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
}

.linkDiv {
  opacity: 0.8;
}

.linkDiv:hover {
  opacity: 1;

  cursor: pointer;
}

body {
  background-color: black;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url("../public/background.jpg");
  background-size: cover;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({
    drawer: false,
    collapse: false,
    collapseOnScroll: true,
    minerAddress: undefined
  }),
  created() {},
  mounted() {   
    //this.$router.replace('/');   
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
    openRoute(route) {
      if (this.$route.path != route) {
        this.$router.push(route);
      }    
      //console.log(this.$route);    
    },
    updateMinerAddress(a) {
      this.minerAddress = a;
    },  
    hideDrawer() {
      //console.log(e);
     if ( !this.drawer ) {
        this.$emit('hide-drawer',false);
        this.drawer = false;
      }
    }
  },
};
</script>

