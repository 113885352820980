<template>
  <v-card
    outlined
    tile
    shaped
    rounded-lg
    class="glass"
    :style="{ backgroundColor: back_color }"
  >
    <div v-if="cardImage" style="display: flex; position: absolute; right: 20px; top: 20px">
      <v-text-field
                v-model="search"
                label="OrderID"            
                outlined              
                clearable
                dense         
                persistent-placeholder     
                style="max-width: 150px; margin-top: -2px; margin-bottom: -20px; margin-left: 5px; margin-right: 10px;"
              ></v-text-field>
      <img style="margin-top: 0px; width: 32px; height: 32px" :src="cardImage" />
    </div>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle style="margin-top: -20px; text-align: left">{{
      subtitle
    }}</v-card-subtitle>

    <v-card-text style="padding: 10px; padding-top: 0px; font-family: monospace">
      <v-data-table
        id="data_table"
        style="background-color: transparent; margin-top: -2px;"
        :headers="computedHeaders"
        :items="data_table"
        :items-per-page="6"
        class="elevation-0"
        dense
        mobile-breakpoint="0"
        :sort-by="currentSort"
        :sort-desc="currentSortDirection"
        multi-sort
        :search="search"
        :calculate-widths="true"
        :footer-props="{
          'disable-items-per-page': true,
          'items-per-page-text': '',
        }"      
        @click:row="handleClick"
      >
      <template v-slot:top>
     
      </template>

        <template v-slot:[`item.id`]="{ item }">
          <pre>{{item.id}}</pre>
        </template>
        <template v-slot:[`item.limit`]="{ item }">
          <span v-if="item.limit == 9999">∞</span>
          <span v-else>{{format4dps(item.limit)}}</span>          
        </template>
        <template v-slot:[`item.price`]="{ item }">          
          <span>{{format4dps(item.price)}}</span>          
        </template>
        <template v-slot:[`item.miners`]="{ item }">          
          <span>{{formatMiners(item.miners)}}</span>          
        </template>
        >
        <template v-slot:[`item.currentSpeed`]="{ item }">
          <pre>{{formatNumber(item.currentSpeed)[0]}} {{formatNumber(item.currentSpeed)[1]}}H/s</pre>
        </template>

        
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style scoped>
#data_table >>> tr:hover {
  /*background: transparent !important;*/
  cursor: default;
}

.nopadding {
  padding: 0px;
}
</style>

<script>
import numeral from 'numeral';

export default {
  name: "NiceHashOrdersCard",
  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
  },
  props: {
    title: undefined,
    subtitle: undefined,
    data_value: {
      default: "-",
    },
    data_table: [],
    back_color: {
      default: "#FFEA80",
    },
    cardImage: undefined
  },
  data: () => ({
    currentSort: ['currentSpeed', 'miners', 'limit'],
    currentSortDirection: [true, true, true],
    search: '',
    headers: [
      { text: "OrderID", align: "start", value:"id" },     
      { text: "Region", align: "start", value: "region", hide: "smAndDown" },
      { text: "Type", align: "start", value: "type", hide: "smAndDown" },
      { text: "Price [BTC/GH/Day]", align: "end", value: "price" },
      { text: "Limit [GH/s]", align: "end", value: "limit", hide: "smAndDown" },
      { text: "Miners", align: "end", value: "miners", hide: "xs" },
      { text: "Hashrate", align: "end", value: "currentSpeed" }      
    ]
  }),
  methods: {
    handleClick(v) {
      return v
      /*window.open(
        "https://explorer.zephyrprotocol.com/block/" + v.bh,
        "_blank"
      );*/
    },
    shortenAddress(a) {
      return a.substr(0, 6) + "..." + a.substr(-6);
    },
    format4dps(l) {
      return numeral(l).format("0,0.0000");
    },
    formatMiners(m) {
      return numeral(m).format("0,0");
    },
    formatNumber(n) {
      if (n < 1000) {
        return [Math.round(10000 * n) / 100, " "];
      }
      var ranges = [
        { divider: 1e18, suffix: "E" },
        { divider: 1e15, suffix: "P" },
        { divider: 1e12, suffix: "T" },
        { divider: 1e9, suffix: "G" },
        { divider: 1e6, suffix: "M" },
        { divider: 1e3, suffix: "k" },
      ];
      for (var i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
          return [
            Math.round(100 * (n / ranges[i].divider)) / 100,
            ranges[i].suffix,
          ];
        }
      }
      return [Math.round(10000 * n) / 10000, " "];
    },
  },
};
</script>
