<template>
  <v-card
    outlined
    tile
    shaped
    rounded-lg
    class="glass"
    :style="{ backgroundColor: back_color }"
  >
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle style="margin-top: -20px; text-align: left">{{
      subtitle
    }}</v-card-subtitle>

    <v-card-text style="padding: 10px; font-family: monospace">
      <v-data-table
        id="data_table"
        style="background-color: transparent;"
        :headers="computedHeaders"
        :items="data_table"
        :items-per-page="5"
        class="elevation-0"
        dense
        mobile-breakpoint="0"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :calculate-widths="true"
        :footer-props="{
          'disable-items-per-page': false,
          'items-per-page-text': 'Payments per page',
        }"
        @click:row="handleClick"
      >
        <template v-slot:[`item.h`]="{ item }">
          <span class="hide-xs hidden-md-and-up"><pre>{{ shortenAddress(item.h) }}</pre></span>
          <span class="hidden-sm-and-down"><pre>{{ item.h }}</pre></span>
        </template>      
        <template v-slot:[`item.t`]="{ item }">
          <pre>{{ item.t.toLocaleDateString() + " " + item.t.toLocaleTimeString() }}</pre>
        </template>      
        <template v-slot:[`item.z`]="{ item }">
          <pre>{{ item.z }}</pre>
        </template>
        <template v-slot:[`item.m`]="{ item }">
          <pre>{{ item.m }}</pre>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style scoped>
#data_table >>> tr:hover {
  /*background: transparent !important;*/
  cursor: default;
}

.nopadding {
  padding: 0px;
}
</style>

<script>
export default {
  name: "PaymentsCard",
  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
  },
  props: {
    title: undefined,
    subtitle: undefined,
    data_value: {
      default: "-",
    },
    data_table: [],
    back_color: {
      default: "#FFEA80",
    },
  },
  data: () => ({
    headers: [
      { text: "Date", align: "end", value: "t" },
      {
        text: "Transaction Hash",
        align: "start",
        value: "h",
        hide: "xs"      
      },
      { text: "Amount [ZEPH]", align: "end", value: "z" },
      { text: "Mixin", align: "end", value: "m", hide: "smAndDown" },
    ],
    sortBy: "t",
    sortDesc: true,
  }),
  methods: {
    handleClick(v) {
      window.open("https://explorer.zephyrprotocol.com/tx/" + v.h, "_blank");
    },
    shortenAddress(a) {
      return a.substr(0, 6) + "..." + a.substr(-6);
    },
  },
};
</script>
