<template>
  <v-card
    outlined
    tile
    shaped
    rounded-lg
    class="glass"
    :style="{ backgroundColor: back_color }"
  >
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle style="margin-top: -20px; text-align: left">{{
      subtitle
    }}</v-card-subtitle>

    <v-card-text style="padding: 10px; font-family: monospace">
      <div style="display: flex; flex-wrap: wrap; gap: 20px; margin-left: 6px; margin-top: -6px">
        <v-text-field
          label="Hashrate"
          v-model.number="selectedHashrate"
          dense
          :disabled="!enableCustomValues"
          suffix="kH/s"
          type="number"
          style="max-width: 100px; font-size: 8pt; max-height: 30px !important"
        ></v-text-field>
        <v-text-field
          label="ZEPH Price"
          v-model.number="selectedPrice"
          dense
          prefix="$"
          type="number"
          :disabled="!enableCustomValues"
          style="max-width: 100px; font-size: 8pt; max-height: 30px !important"
        ></v-text-field>
        <v-text-field
          label="Block Reward"
          v-model.number="selectedReward"
          dense
          suffix="ZEPH"
          type="number"
          :disabled="!enableCustomValues"
          style="max-width: 100px; font-size: 8pt; max-height: 30px !important"
        ></v-text-field>
        <v-text-field
          label="Power"
          v-model.number="selectedPower"
          dense
          suffix="W"
          type="number"
          :disabled="!enableCustomValues"
          style="max-width: 100px; font-size: 8pt; max-height: 30px !important"
        ></v-text-field>
        <v-text-field
          label="Power Cost"
          v-model.number="selectedPowerCost"
          dense
          prefix="$"
          suffix="KWh"
          type="number"
          :disabled="!enableCustomValues"
          style="max-width: 100px; font-size: 8pt; max-height: 30px !important"
        ></v-text-field>
        <!--<v-select
          :items="items"
          v-model="selectedDifficulty"
          label="Difficulty"
          dense
          :disabled="!enableCustomValues"
          style="max-width: 130px; font-size: 8pt; max-height: 10px !important"
        ></v-select> -->
      </div>
      <div>
        <v-checkbox
          v-model="enableCustomValues"
          label="Custom values"
          dense
          style="
            margin-top: 20px;
            font-size: 6pt !important;
            position: absolute;
            right: 20px;
            top: -5px;
          "
        >
          <template v-slot:label
            ><span style="font-size: 9pt; margin-left: -5px"
              >Enable Custom Values</span
            ></template
          >
        </v-checkbox>
      </div>
      <div style="margin-top: 45px; display: flex; justify-content: center;">
        <v-simple-table id="simple_table" style="width: 100%; background-color: transparent; font-size: 8pt !important; margin: 5px; margin-top: -20px;" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th style="max-width: 120px;" class="text-center"></th>
                <th colspan="2" class="text-center" style="border: 1px solid #1C1C1C;">DAILY</th>
                <th colspan="2" class="text-center hidden-xs-only" style="border: 1px solid #1C1C1C;">WEEKLY</th>
                <th colspan="2" class="text-center hidden-xs-only" style="border: 1px solid #1C1C1C;">MONTHLY</th>               
              </tr>
              <tr>
                <th style="max-width: 120px; border: 1px solid #1C1C1C;" class="text-left">Difficulty</th>                
                <th style="border: 1px solid #1C1C1C;" class="text-right">ZEPH</th>
                <th style="border: 1px solid #1C1C1C;" class="text-right">USD</th>  
                <!--<th style="border: 1px solid #1C1C1C;" class="text-right">BTC</th> --> 
                <th style="border: 1px solid #1C1C1C;" class="text-right hidden-xs-only">ZEPH</th>
                <th style="border: 1px solid #1C1C1C;" class="text-right hidden-xs-only">USD</th>  
                <!--<th style="border: 1px solid #1C1C1C;" class="text-right hidden-xs-only">BTC</th> -->
                <th style="border: 1px solid #1C1C1C;" class="text-right hidden-xs-only">ZEPH</th>
                <th style="border: 1px solid #1C1C1C;" class="text-right hidden-xs-only">USD</th>                            
                <!--<th style="border: 1px solid #1C1C1C;" class="text-right hidden-xs-only">BTC</th> -->
              </tr>              
            </thead>
            <tbody>
              <tr>
                <th class="text-left" style="max-width: 120px; font-size: 9pt; border: 1px solid #1C1C1C;">Current</th>
                <td class="text-right" style="color: cyan; font-size: 9pt; border: 1px solid #1C1C1C;">{{ baseCalc(1, difficulty) }}</td>
                <td class="text-right" style="font-size: 9pt; border: 1px solid #1C1C1C;" v-html="dollarReturn(1, difficulty)"></td>                
                <!--<td class="text-right" style="font-size: 9pt; border: 1px solid #1C1C1C;">{{ btcReturn(1, difficulty) }}</td>-->
                <td class="text-right hidden-xs-only" style="color: cyan; font-size: 9pt; border: 1px solid #1C1C1C;">{{ baseCalc(7, difficulty) }}</td>
                <td class="text-right hidden-xs-only" style="font-size: 9pt; border: 1px solid #1C1C1C;" v-html="dollarReturn(7, difficulty)"></td>  
                <!--<td class="text-right hidden-xs-only" style="font-size: 9pt; border: 1px solid #1C1C1C;">{{ btcReturn(7, difficulty) }}</td>-->
                <td class="text-right hidden-xs-only" style="color: cyan; font-size: 9pt; border: 1px solid #1C1C1C;">{{ baseCalc(30, difficulty) }}</td>
                <td class="text-right hidden-xs-only" style="font-size: 9pt; border: 1px solid #1C1C1C;" v-html="dollarReturn(30, difficulty)"></td>  
                <!--<td class="text-right hidden-xs-only" style="font-size: 9pt; border: 1px solid #1C1C1C;">{{ btcReturn(30, difficulty)}}</td>-->
              </tr>
              <tr>
                <th class="text-left" style="max-width: 120px; font-size: 9pt; border: 1px solid #1C1C1C;">3-Day Avg</th>
                <td class="text-right" style="color: cyan; font-size: 9pt; border: 1px solid #1C1C1C;">{{ baseCalc(1, difficulty3d) }}</td>
                <td class="text-right" style="font-size: 9pt; border: 1px solid #1C1C1C;" v-html="dollarReturn(1, difficulty3d)"></td>                
                <!--<td class="text-right" style="font-size: 9pt; border: 1px solid #1C1C1C;">{{ btcReturn(1, difficulty3d) }}</td> -->  
                <td class="text-right hidden-xs-only" style="color: cyan; font-size: 9pt; border: 1px solid #1C1C1C;">{{ baseCalc(7, difficulty3d) }}</td>
                <td class="text-right hidden-xs-only" style="font-size: 9pt; border: 1px solid #1C1C1C;" v-html="dollarReturn(7, difficulty3d)"></td>
                <!--<td class="text-right hidden-xs-only" style="font-size: 9pt; border: 1px solid #1C1C1C;">{{ btcReturn(7, difficulty3d) }}</td>-->
                <td class="text-right hidden-xs-only" style="color: cyan; font-size: 9pt; border: 1px solid #1C1C1C;">{{ baseCalc(30, difficulty3d) }}</td>
                <td class="text-right hidden-xs-only" style="font-size: 9pt; border: 1px solid #1C1C1C;" v-html="dollarReturn(30, difficulty3d)"></td>
                <!--<td class="text-right hidden-xs-only" style="font-size: 9pt; border: 1px solid #1C1C1C;">{{ btcReturn(30, difficulty3d)}}</td>-->
              </tr>
              <tr>
                <th class="text-left" style="max-width: 120px; font-size: 9pt; border: 1px solid #1C1C1C;">7-Day Avg</th>
                <td class="text-right" style="color: cyan; font-size: 9pt; border: 1px solid #1C1C1C;">{{ baseCalc(1, difficulty7d) }}</td>
                <td class="text-right" style="font-size: 9pt; border: 1px solid #1C1C1C;" v-html="dollarReturn(1, difficulty7d)"></td>
                <!--<td class="text-right" style="font-size: 9pt; border: 1px solid #1C1C1C;">{{ btcReturn(1, difficulty7d) }}</td>-->
                <td class="text-right hidden-xs-only" style="color: cyan; font-size: 9pt; border: 1px solid #1C1C1C;">{{ baseCalc(7, difficulty7d) }}</td>
                <td class="text-right hidden-xs-only" style="font-size: 9pt; border: 1px solid #1C1C1C;" v-html="dollarReturn(7, difficulty7d)"></td>
                <!--<td class="text-right hidden-xs-only" style="font-size: 9pt; border: 1px solid #1C1C1C;">{{ btcReturn(7, difficulty7d) }}</td>-->
                <td class="text-right hidden-xs-only" style="color: cyan; font-size: 9pt; border: 1px solid #1C1C1C;">{{ baseCalc(30, difficulty7d) }}</td>
                <td class="text-right hidden-xs-only" style="font-size: 9pt; border: 1px solid #1C1C1C;" v-html="dollarReturn(30, difficulty7d)"></td>                
                <!--<td class="text-right hidden-xs-only" style="font-size: 9pt; border: 1px solid #1C1C1C;">{{ btcReturn(30, difficulty7d)}}</td>-->
              </tr>
             
            </tbody>
          </template>
        </v-simple-table>
       
      </div>
      <p style="margin: 20px; margin-top: 0px; margin-bottom: 0px; font-size: 5pt; line-height: 8pt; text-align: left;"><span style="vertical-align: super;">*</span> Estimated rewards excluding pool, network and transactions fees. Calculation assumes miner is running 24/7. Network difficulty, ZEPH price and hashrate can vary substantialy with time and turn a positive profit expectation into a very negative one.</p>
    </v-card-text>
  </v-card>
</template>

<style scoped>

#simple_table >>> tr:hover {
  background: transparent !important;
  cursor: default;
}

.nopadding {
  padding: 0px;
}
.pnlGreen {
  color: #ADE8D4 !important;
  background-color: transparent;
}

.pnlRed {
  color: #F2AAB0 !important;
  background-color: transparent;
}
</style>

<script>
import Numeral from "numeral";

export default {
  name: "CalcCard",
  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
  },
  props: {
    title: undefined,
    subtitle: undefined,
    hashrate: undefined,
    difficulty: undefined,
    block_reward: undefined,
    difficulty3d: undefined,
    difficulty7d: undefined,
    price: undefined,
    priceBTC: undefined,
    back_color: undefined,
  },
  watch: {
    hashrate: function (val) {
      if (!this.enableCustomValues) {
        this.selectedHashrate = Math.round((100 * val) / 1000) / 100;       
      }
    },
    price: function (val) {
      if (!this.enableCustomValues)
        this.selectedPrice = Math.round(100 * val) / 100;
    },
    block_reward: function (val) {
      if (!this.enableCustomValues)
        this.selectedReward = Math.round(100 * val) / 100;
    },
  },
  data: () => ({
    enableCustomValues: false,
    estimatedDay: 0,
    estimatedWeek: 0,
    estimatedMonth: 0,
    items: ["Current", "3 Day Average", "7 Day Average"],
    selectedDifficulty: "Current",
    selectedHashrate: 0,
    selectedPrice: 0,
    selectedReward: 0,
    selectedPower: 0,
    selectedPowerCost: 0
  }),
  mounted() {
    this.enableCustomValues = false;
    this.selectedDifficulty = "Current";
    this.selectedHashrate = Math.round((100 * this.hashrate) / 1000) / 100;
    this.selectedPrice = Math.round(100 * this.price) / 100;
    this.selectedReward = Math.round(100 * this.block_reward) / 100;
    if (this.selectedHashrate == 0) this.selectedHashrate = 650;
  },
  methods: {
    formatDollar(d) {
      return Numeral(d).format("$0,0.00");
    },
    formatBTC(d) {
      return '₿' + Numeral(d).format("0,0.000000");
    },
    baseCalc(days, diff) {
      return Math.round(10000*1000*(this.selectedHashrate / diff) * this.selectedReward * 3600 * 24 * days)/10000;
    },
    dollarReturn(days, diff) {
      let base = this.baseCalc(days, diff);
      let ret = (this.selectedPrice*base) - (days*24*this.selectedPower/1000 * this.selectedPowerCost);
      //return this.formatDollar(ret)
      return ret >= 0 ? '<span class="green--text text--lighten-1">'  + this.formatDollar(ret)  + '</span>' : '<span class="red--text text--lighten-1">' + this.formatDollar(ret) + '</span>';
    },
    btcReturn(days, diff) {
      let impliedBTCUSDrate = this.price/this.priceBTC;     
      let base = this.baseCalc(days, diff);
      let ret = (this.selectedPrice*base/impliedBTCUSDrate) - (days*24*this.selectedPower/1000 * this.selectedPowerCost);
      return ret >= 0 ? '<span class="green">'  + this.formatBTC(ret)  + '</span>' : '<span class="red">' + this.formatBTC(ret) + '</span>';
    }
  },
};
</script>
