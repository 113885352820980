<template>
  <v-card
    outlined
    tile
    shaped
    rounded-lg
    class="glass"
    :style="{ backgroundColor: back_color }"
  >
    <div v-if="cardImage" style="position: absolute; right: 20px; top: 20px">
      <img style="width: 42px; height: 42px" :src="cardImage" />
    </div>
    <v-card-title>{{ title }} </v-card-title>
    <v-card-subtitle
      v-html="subtitle"
      style="margin-top: -20px; margin-bottom: 0px; text-align: left"
    ></v-card-subtitle>

    <v-card-text
      v-on:mouseover="mouseover"
      v-on:mouseleave="mouseleave"
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        border: 0px solid gray;
        margin-top: -20px;
        padding: 10px;
        padding-top: 0px;
        padding-bottom: 0px;
        height: 210px;
      "
    >
      <div style="border: 0px solid red; margin: 0px; width: 100%">
        <div
          style="
            border: 0px solid blue;
            height: 120px;
            margin: 0px;
            width: 100%;
          "
        >
          <canvas
            v-if="chart_data.length > 0"
            ref="chart"
            style="position: relative; z-index: 10"
          ></canvas>
        </div>

        <div
          class="bringToTop"
          ref="bigValue"
          style="
            /*margin-top: -70px;*/
            border: 0px solid yellow;
            margin: 0px;
            margin-top: -120px;
            left: 0px;
            color: white;
            font-weight: normal;
            font-size: 52pt;
            width: 100%;
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <Transition
            mode="out-in"
            style="
              border: 0px solid white;
              margin-top: 0px;
              width: 100%;
              min-height: 120px;
              max-height: 120px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <div style="margin-top: 50px" :key="data_value">
              {{ data_value }}
            </div>
          </Transition>
          <div
            style="margin-top: 20px; margin-bottom: 10px; font-size: 14pt"
            :key="data_units"
          >
            {{ data_units }}
          </div>
        </div>

        <div style="margin-top: 10px; margin-bottom: 0px" v-if="data_table">
          <div v-for="(o, idx) in Object.keys(data_table)" :key="o">
            <div
              style="
                display: flex;
                width: 100%;
                font-size: 9pt;
                border: 0px solid yellow;
              "
            >
              <div
                style="
                  font-weight: normal;
                  color: white;
                  text-align: left;
                  width: 60%;
                "
              >
                {{ o }}
              </div>
              <div style="text-align: right; width: 70%">
                <div v-if="number_colors && data_table[o].includes('%')">
                  <span
                    v-if="
                      parseFloat(
                        data_table[o].replace('$', '').replace('%', '')
                      ) < 0
                    "
                    style="color: #e6686e"
                    >{{ data_table[o] }}</span
                  >
                  <span
                    v-if="
                      parseFloat(
                        data_table[o].replace('$', '').replace('%', '')
                      ) >= 0
                    "
                    style="color: #21f07e"
                    >{{ data_table[o] }}</span
                  >
                </div>
                <div v-else>
                  <div v-if="doTransition">
                    <transition name="slide-fade" mode="out-in">
                      <div :key="data_table[o]">
                        {{ data_table[o] }}
                      </div>
                    </transition>
                  </div>
                  <div v-else>
                    {{ data_table[o] }}
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="idx < Object.keys(data_table).length - 1"
              style="margin-bottom: 5px"
            >
              <v-divider />
            </div>
          </div>
        </div>

        <!-- last before card text end-->
      </div>

      <!--
    
    -->
    </v-card-text>
  </v-card>
</template>

<style scoped>
.bringToTop {
  position: relative;
  z-index: 17;
}

.sendToBack {
  position: relative;
  z-index: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  /*transform: translateX(10px);*/  
  opacity: 0;
}

</style>
<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import "chartjs-adapter-moment";
import Numeral from "numeral";

export default {
  name: "StatCard",
  components: {},
  props: {
    title: undefined,
    subtitle: undefined,
    data_value: {
      default: "-",
    },
    data_table: {},
    doTransition: { default: false },
    back_color: {
      default: "#FFEA80",
    },
    data_units: undefined,
    chart_data: {
      type: Array(),
      default() {
        return [];
      },
    },
    chart_color: {
      default: "rgb(180, 240, 190, 0.5)",
    },
    number_colors: {
      default: false,
    },
    chart_type: {
      default: "line",
    },
    cardImage: {
      default: undefined,
    },
    tooltipBaseUnit: { default: "" },
    tooltipLabel: { default: "" },
  },
  watch: {
    chart_data: {
      deep: true,
      handler() {
        this.updateChartData();
        if (
          this.$refs.chart &&
          this.$refs.chart.getContext("2d") &&
          !this.chart_ready
        )
          this.buildChart();

        /*this.chart.destroy();
        this.buildChart();*/
      },
    },
  },
  data: () => ({
    chart: undefined,
    labels: [],
    data_xy: [],
    ctx: undefined,
    chart_ready: false,
  }),
  created() {
    if (this.chart_data) {
      this.updateChartData();
    }
  },
  mounted() {
    if (this.$refs.chart) this.buildChart();
  },
  methods: {
    mouseover() {
      this.$refs["bigValue"].classList.value = "sendToBack";
    },
    mouseleave() {
      this.$refs["bigValue"].classList.value = "bringToTop";
    },
    formatNumber(n) {
      if (n < 1000) {
        return [Math.round(10000 * n) / 10000, " "];
      }
      var ranges = [
        { divider: 1e18, suffix: "E" },
        { divider: 1e15, suffix: "P" },
        { divider: 1e12, suffix: "T" },
        { divider: 1e9, suffix: "G" },
        { divider: 1e6, suffix: "M" },
        { divider: 1e3, suffix: "k" },
      ];
      for (var i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
          return [
            Math.round(10000 * (n / ranges[i].divider)) / 10000,
            ranges[i].suffix,
          ];
        }
      }
      return [Math.round(10000 * n) / 10000, " "];
    },
    updateChartData() {
      this.data_xy = [];
      this.chart_data.forEach((a) => {
        this.data_xy.push({
          x: new Date(1000 * a[0]),
          y: a[1],
        });
      });

      if (this.$chart) {
        this.$chart.data.datasets[0].data = this.data_xy;
        this.$chart.update();
        //console.log(this.title + " Chart updated.");
      }
    },
    buildChart() {
      const ctx = this.$refs.chart.getContext("2d");
      var that = this;
      this.$chart = new Chart(ctx, {
        type: this.chart_type,
        data: {
          datasets: [
            {
              label: this.tooltipLabel,
              data: this.data_xy,
              borderColor: "transparent",
              backgroundColor: this.chart_color,
              maxBarThickness: 10,
              maxBarLength: 2,
              fill: {
                target: "origin",
                above: this.chart_color, // Area will be red above the origin
                below: "rgb(0, 0, 255)", // And blue below the origin
              },
              pointRadius: 0,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
            mode: "label",
          },
          interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
          },
          plugins: {
            legend: false,
            filler: {
              propagate: true,
            },
            tooltip: {
              backgroundColor: "rgba(0,0,0,1)",
              zIndex: 9999999,
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";

                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    let parsed = that.formatNumber(context.parsed.y);
                    label +=
                      Numeral(parsed[0]).format("0,0.00") +
                      " " +
                      parsed[1] +
                      that.tooltipBaseUnit;
                    //label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                  }
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              display: false,
              type: "time",
              time: {
                unit: "hour",
                paser: "HH:mm",
              },
            },
            y: {
              display: false,
            },
          },
        },
      });

      this.chart_ready = true;

      //console.log(this.$refs);
      //this.chart =  a;
      /*this.chart = new Chart(this.ctx, {
        type: "line",
        //labels: this.data_x,
        data: { datasaets: [
          {
            label: "My First Dataset",
            data: this.data_xy,
            fill: false,            
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
            yAxisID: 'y',
            xAxisID: 'x',
          },
        ]},

        options: {
          responsive: true,          
          scales: {
            x: 
              {
                ticks: {
                  fontColor: "white",
                  fontSize: "9",
                  autoSkip: true,
                  source: this.labels,
                  maxRotation: 0,
                  autoSkipPadding: 6,
                },
                type: "time",
                distribution: "series",
                offset: false,
                time: {
                  //unit: 'day',
                  displayFormats: {
                    hour: "h",
                    day: "D",
                    month: "MMM",
                  },
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "date",
                },
                gridLines: {
                  color: "#202020",
                },
              },
            y: 
              {
                
                ticks: {
                  beginAtZero: false,
                  fontColor: "white",
                  fontSize: "6",
                },
                display: true,
                position: "right",
                scaleLabel: {
                  display: false,
                  labelString: "price",
                },
                gridLines: {
                  color: "#202020",
                },
              },            
          },
        },
      });*/
    },
  },
};
</script>
