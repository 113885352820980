<template>
    <v-card
      outlined
      tile
      shaped
      rounded-lg
      class="glass"
      :style="{ backgroundColor: back_color }"
    >
      <v-card-title>{{ title }}</v-card-title>
      <v-card-subtitle style="margin-top: -20px; text-align: left">{{
        subtitle
      }}</v-card-subtitle>

      <v-card-text style="padding: 10px; font-family: monospace;">
        <v-data-table
          id="data_table"
          :headers="computedHeaders"
          :items="data_table"
          :items-per-page="5"
          class="elevation-0"
          dense          
          mobile-breakpoint="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"       
          :calculate-widths="true"
          :footer-props="{ 'disable-items-per-page': false, 'items-per-page-text': 'Workers per page' }"
          style="background-color: transparent; font-size: 8pt !important"
        >
        <template v-slot:[`item.hashrate`]="{ item }"><pre>{{item.hashrate_display}}</pre></template>
        <template v-slot:[`item.hashrate_1h`]="{ item }"><pre>{{item.hashrate_1h_display}}</pre></template>
        <template v-slot:[`item.hashrate_6h`]="{ item }"><pre>{{item.hashrate_6h_display}}</pre></template>
        <template v-slot:[`item.hashrate_24h`]="{ item }"><pre>{{item.hashrate_24h_display}}</pre></template>
        <template v-slot:[`item.lastShare`]="{ item }"><span>{{item.lastShare_display}}</span></template>
        <template v-slot:[`item.hashes`]="{ item }">{{item.hashes_display}}</template>
       
      </v-data-table>
      </v-card-text>
    </v-card>  
</template>

<style scoped>
#data_table >>> tr:hover {
  background: transparent !important;
  cursor: default;
}
.nopadding {
  padding: 0px;
}
</style>

<script>

export default {
  name: "WorkersCard",
  computed: {
    computedHeaders () {
      return this.headers.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])  
    }
  },
  props: {
    title: undefined,
    subtitle: undefined,
    data_value: {
      default: "-",
    },
    data_table: [],
    back_color: {
      default: "#FFEA80",
    }
  },
  data: () => ({
    headers: [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
            /*width: 150*/
          },
          { text: 'Hash Rate', align: 'end', value: 'hashrate' },
          { text: 'HR (1h)', align: 'end',value: 'hashrate_1h',  hide: 'xs' },
          { text: 'HR (6h)', align: 'end',value: 'hashrate_6h',  hide: 'smAndDown' },
          { text: 'HR (24h)', align: 'end',value: 'hashrate_24h',  hide: 'xs' },
          { text: 'Last Share', align: 'end', value: 'lastShare', hide: 'xsAndDown', /*width: 170*/ },
        { text: 'Total Shares', align: 'end',value: 'hashes',  hide: 'smAndDown', /*width: 130*/ }        
        ],
        sortBy: 'lastShare',
        sortDesc: true,
  }),
};
</script>
