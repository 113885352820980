<template>
  <v-card
    outlined
    tile
    shaped
    rounded-lg
    class="glass"
    :style="{ backgroundColor: back_color }"
  >
    <div v-if="cardImage" style="position: absolute; right: 20px; top: 20px">
      <img style="width: 32px; height: 32px" :src="cardImage" />
    </div>
    <v-card-title>{{ title }} </v-card-title>
    <v-card-subtitle
      style="margin-top: -20px; margin-bottom: 0px; text-align: left"
      >{{ subtitle }}</v-card-subtitle
    >

    <v-card-text
      v-on:mouseover="mouseover"
      v-on:mouseleave="mouseleave"
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        border: 0px solid gray;
        margin-top: -20px;
        padding: 10px;
        padding-top: 10px;
        padding-bottom: 0px;
        height: 210px;
      "
    >
      <div style="border: 0px solid red; margin: 0px; width: 100%">
        <div
          style="
            border: 0px solid blue;
            height: 120px;
            margin: 0px;
            width: 100%;
          "
        >
          <canvas
            v-if="chart_data"
            ref="chart"
            style="position: relative; z-index: 15"
          ></canvas>
        </div>

        <div
          class="bringToTop"
          ref="bigValue"
          style="
            pointer-events: none;
            /*margin-top: -70px;*/
            border: 0px solid yellow;
            margin: 0px;
            margin-top: -125px;
            left: 0px;
            color: white;
            font-weight: normal;
            font-size: 52pt;
            width: 100%;
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
          "
        >
          <Transition
            mode="out-in"
            style="
              border: 0px solid white;
              margin-top: 0px;
              width: 100%;
              min-height: 120px;
              max-height: 120px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <div style="margin-top: 50px" :key="data_value">
              {{ data_value }}
            </div>
          </Transition>
          <div
            style="margin-top: 20px; margin-bottom: 10px; font-size: 14pt"
            :key="data_units"
          >
            {{ data_units }}
          </div>
        </div>

        <div style="margin-top: 10px; margin-bottom: 0px" v-if="data_table">
          <div v-for="(o, idx) in Object.keys(data_table)" :key="o">
            <div
              style="
                display: flex;
                width: 100%;
                font-size: 9pt;
                border: 0px solid yellow;
              "
            >
              <div
                style="
                  font-weight: normal;
                  color: white;
                  text-align: left;
                  width: 60%;
                "
              >
                {{ o }}
              </div>
              <div style="text-align: right; width: 70%">
                <div v-if="number_colors && data_table[o].includes('%')">
                  <span
                    v-if="
                      parseFloat(
                        data_table[o].replace('$', '').replace('%', '')
                      ) < 0
                    "
                    style="color: #e6686e"
                    >{{ data_table[o] }}</span
                  >
                  <span
                    v-if="
                      parseFloat(
                        data_table[o].replace('$', '').replace('%', '')
                      ) >= 0
                    "
                    style="color: #21f07e"
                    >{{ data_table[o] }}</span
                  >
                </div>
                <div v-else>
                  {{ data_table[o] }}
                </div>
              </div>
            </div>

            <div
              v-if="idx < Object.keys(data_table).length - 1"
              style="margin-bottom: 5px"
            >
              <v-divider />
            </div>
          </div>
        </div>

        <!-- last before card text end-->
      </div>

      <!--
    
    -->
    </v-card-text>
  </v-card>
</template>

<style scoped>
.bringToTop {
  position: relative;
  z-index: 17;
}

.sendToBack {
  position: relative;
  z-index: 1;
}

#data_table >>> tr:hover {
  /*background: transparent !important;*/
  cursor: default;
}

.nopadding {
  padding: 0px;
}
</style>

<script>
import numeral from "numeral";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import "chartjs-adapter-moment";

export default {
  name: "NiceHashCard",
  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
  },
  props: {
    title: undefined,
    subtitle: undefined,
    data_value: {
      default: "-",
    },
    data_units: undefined,
    data_table: [],
    back_color: {
      default: "#FFEA80",
    },
    cardImage: undefined,
    chart_data: undefined,
    chart_color: {
      default: "rgb(180, 240, 190, 0.5)",
    },
    number_colors: {
      default: false,
    },
    chart_type: {
      default: "line",
    },
  },
  data: () => ({
    chart: undefined,
    labels: [],
    data_xy: [],
    data_xy1: [],
    ctx: undefined,
    headers: [
      { text: "OrderID", align: "end", value: "id" },
      { text: "Region", align: "start", value: "region", hide: "smAndDown" },
      /*{ text: "Type", align: "start", value: "type", hide: "lgAndDown" },*/
      { text: "Price", align: "end", value: "price", hide: "smAndDown" },
      { text: "Limit", align: "end", value: "limit", hide: "xs" },
      { text: "Miners", align: "end", value: "miners", hide: "xs" },
      { text: "Hashrate", align: "end", value: "currentSpeed" },
    ],
    sortBy: "currentSpeed",
    sortDesc: true,
  }),
  watch: {
    chart_data: {
      deep: true,
      handler() {
        this.updateChartData();
      },
    },
  },
  created() {
    this.updateChartData();
    if (this.chart_data) {
      //this.updateChartData();
    }
  },
  mounted() {
    this.buildChart();
  },
  methods: {
    mouseover() {
      this.$refs["bigValue"].classList.value = "sendToBack";
    },
    mouseleave() {
      this.$refs["bigValue"].classList.value = "bringToTop";
    },
    updateChartData() {
      this.data_xy = [];
      this.data_xy1 = [];
      this.chart_data.forEach((a) => {
        this.data_xy.push({
          x: new Date(1000 * a[0]),
          y: Math.round((100 * a[1]) / 1e6) / 100,
        });
        this.data_xy1.push({
          x: new Date(1000 * a[0]),
          y: Math.round(1000 * a[2]) / 100,
        });
      });

      if (this.$chart) {
        this.$chart.data.datasets[1].data = this.data_xy;
        this.$chart.data.datasets[0].data = this.data_xy1;
        this.$chart.update();
        //console.log(this.title + " Chart updated.");
      }
    },
    buildChart() {
      const ctx = this.$refs.chart.getContext("2d");

      this.$chart = new Chart(ctx, {
        type: this.chart_type,
        data: {
          datasets: [
            {
              label: "Price",
              data: this.data_xy1,
              yAxisID: "y1",
              borderColor: "#003f5c",
              borderWidth: 1,
              backgroundColor: this.chart_color,
              maxBarThickness: 10,
              maxBarLength: 2,
              /*fill: {
                target: "origin",
                above: this.chart_color, // Area will be red above the origin
                below: "rgb(0, 0, 255)", // And blue below the origin
              },*/
              pointRadius: 0,
            },
            {
              label: "Hashrate",
              data: this.data_xy,
              yAxisID: "y",
              borderColor: "transparent",
              backgroundColor: this.chart_color,
              maxBarThickness: 10,
              maxBarLength: 2,
              fill: {
                target: "origin",
                above: this.chart_color, // Area will be red above the origin
                below: "rgb(0, 0, 255)", // And blue below the origin
              },
              pointRadius: 0,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            enabled: true,
            mode: "label",
          },
          interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
          },
          plugins: {
            legend: false,
            filler: {
              propagate: true,
            },
            tooltip: {
              backgroundColor: "rgba(0,0,0,1)",
              zIndex: 9999999,
            },
          },
          scales: {
            x: {
              display: false,
              type: "time",
              time: {
                unit: "hour",
                paser: "HH:mm",
              },
            },
            y: {
              type: "linear",
              display: true,
              position: "left",
              ticks: {
                font: {
                  size: 9,
                  family: "vazir",
                },
              },
            },
            y1: {
              type: "linear",
              display: true,
              position: "right",
              ticks: {
                font: {
                  size: 9,
                  family: "vazir",
                },
              },
            },
          },
        },
      });

      //console.log(this.$refs);
      //this.chart =  a;
      /*this.chart = new Chart(this.ctx, {
        type: "line",
        //labels: this.data_x,
        data: { datasaets: [
          {
            label: "My First Dataset",
            data: this.data_xy,
            fill: false,            
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
            yAxisID: 'y',
            xAxisID: 'x',
          },
        ]},

        options: {
          responsive: true,          
          scales: {
            x: 
              {
                ticks: {
                  fontColor: "white",
                  fontSize: "9",
                  autoSkip: true,
                  source: this.labels,
                  maxRotation: 0,
                  autoSkipPadding: 6,
                },
                type: "time",
                distribution: "series",
                offset: false,
                time: {
                  //unit: 'day',
                  displayFormats: {
                    hour: "h",
                    day: "D",
                    month: "MMM",
                  },
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "date",
                },
                gridLines: {
                  color: "#202020",
                },
              },
            y: 
              {
                
                ticks: {
                  beginAtZero: false,
                  fontColor: "white",
                  fontSize: "6",
                },
                display: true,
                position: "right",
                scaleLabel: {
                  display: false,
                  labelString: "price",
                },
                gridLines: {
                  color: "#202020",
                },
              },            
          },
        },
      });*/
    },
    handleClick(v) {
      window.open(
        "https://explorer.zephyrprotocol.com/block/" + v.bh,
        "_blank"
      );
    },
    shortenAddress(a) {
      return a.substr(0, 6) + "..." + a.substr(-6);
    },
    format4dps(l) {
      return numeral(l).format("0,0.0000");
    },
    formatMiners(m) {
      return numeral(m).format("0,0");
    },
    formatNumber(n) {
      if (n < 1000) {
        return [Math.round(10000 * n) / 100, " "];
      }
      var ranges = [
        { divider: 1e18, suffix: "E" },
        { divider: 1e15, suffix: "P" },
        { divider: 1e12, suffix: "T" },
        { divider: 1e9, suffix: "G" },
        { divider: 1e6, suffix: "M" },
        { divider: 1e3, suffix: "k" },
      ];
      for (var i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
          return [
            Math.round(100 * (n / ranges[i].divider)) / 100,
            ranges[i].suffix,
          ];
        }
      }
      return [Math.round(10000 * n) / 10000, " "];
    },
  },
};
</script>
