<template>
  <v-card
    outlined
    tile
    shaped
    rounded-lg
    class="glass"
    :style="{ backgroundColor: back_color }"
  >
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle style="margin-top: -20px; text-align: left">{{
      subtitle
    }}</v-card-subtitle>

    <v-card-text style="padding: 10px; font-family: monospace">
      <v-data-table
        id="data_table"
        style="background-color: transparent;"
        :headers="computedHeaders"
        :items="data_table"
        :items-per-page="5"
        class="elevation-0"
        dense
        mobile-breakpoint="0"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :calculate-widths="true"
        :footer-props="{
          'disable-items-per-page': false,
          'items-per-page-text': 'Blocks per page',
        }"
        @click:row="handleClick"
      >
        <template v-slot:[`item.t`]="{ item }">
          <pre>{{ item.t.toLocaleDateString() + " " + item.t.toLocaleTimeString() }}</pre>
        </template>
        <template v-slot:[`item.bh`]="{ item }">
          <span class="hide-xs hidden-xl-and-up"><pre>{{ shortenAddress(item.bh) }}</pre></span>          
        </template>     

        <template v-slot:[`item.e`]="{ item }">
          <div style="display: flex; text-align: right; justify-content: right;">
         <div>{{ formatEffort(item.e) }}&nbsp;</div>
         <div style="margin-top: 2px;" v-if="item.sp == 'solo'"><span class="fi fi-rr-user"
            ></span
          ></div>
          <div style="margin-top: 2px;" v-else><span class="fi fi-rr-users"></span></div>
        </div>
        </template>

        <template v-slot:[`item.r`]="{ item }">
          <div v-if="item.r == 0" style="display: flex; justify-content: right;">           
            <v-progress-linear color="indigo" style="height: 16px; max-width: 45px;" :value="Math.round(100*((item.tg)/61))">
              <template v-slot:default="{ value }">
                <span style="font-size: 6pt; margin-top: 2px;">{{61-Math.round((value*61)/100)}} left</span>
              </template>
            </v-progress-linear>
                    
          </div>
          <div v-else>{{ item.r }}</div>
         

        </template>
        <template v-slot:[`item.s`]="{ item }">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <span v-if="item.s == 0" class="fi fi-rr-lock-open-alt"></span>
            <span v-else class="fi fi-rr-lock"></span>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style scoped>
#data_table >>> tr:hover {
  /*background: transparent !important;*/
  cursor: default;
}

.nopadding {
  padding: 0px;
}
</style>

<script>
import Numeral from 'numeral';

export default {
  name: "PoolBlocksCard",
  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
  },
  props: {
    title: undefined,
    subtitle: undefined,
    data_value: {
      default: "-",
    },
    data_table: [],
    back_color: {
      default: "#FFEA80",
    },
  },
  data: () => ({
    headers: [
      { text: "Time Found", align: "end", value: "t" },     
      { text: "Height", align: "end", value: "h", hide: "smAndDown" },
      { text: "Difficulty", align: "end", value: "d", hide: "smAndDown" },
      { text: "Block Hash", align: "start", value: "bh", hide: "xs" },
      { text: "Miner Address", align: "start", value: "a", hide: "smAndDown" },
      { text: "Effort", align: "end", value: "e", hide: "smAndDown" },
      { text: "Reward", align: "end", value: "r" },
      { text: "", align: "start", value: "s", hide: "smAndDown" },
    ],
    sortBy: "t",
    sortDesc: true,
  }),
  methods: {
    formatEffort(e) {
      return Numeral(e).format("0,0.0%");
    },
    handleClick(v) {
      window.open(
        "https://explorer.zephyrprotocol.com/block/" + v.bh,
        "_blank"
      );
    },
    shortenAddress(a) {
      return a.substr(0, 6) + "..." + a.substr(-6);
    },
  },
};
</script>
