<template>
  <v-card
    outlined
    tile
    shaped
    rounded-lg
    class="glass"
    :style="{ backgroundColor: back_color }"
  >
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle style="margin-top: -20px; text-align: left">{{
      subtitle
    }}</v-card-subtitle>

    <v-card-text style="padding: 10px; padding-top: 0px; font-family: monospace; height: 250px; overflow-y: auto; overscroll-behavior: contain;">
      <v-data-table
        id="data_table"
        style="background-color: transparent; margin-top: -10px;"
        :headers="computedHeaders"
        :items="data_table"
        :items-per-page="6"
        item-key="headline"
        class="elevation-1"
        dense
        mobile-breakpoint="0"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :calculate-widths="false"
        :footer-props="{
          'disable-items-per-page': true,
          'items-per-page-text': 'Articles per page',
        }"
        :expanded.sync="expanded"
        :single-expand="true"
        show-expand
        @click="expanded = !expanded"
        hide-default-footer
      >
        <template v-slot:[`item.t`]="{ item }">
          <span style="font-size: 8pt;"><pre>{{
            item.t.toLocaleDateString()
          }}</pre></span>
        </template>
        <template v-slot:[`item.headline`]="{ item }">
          <div style="display: flex; font-size: 8pt;" v-html="item.headline"
            ></div
          >
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div v-html="item.text" style="text-align: justify; padding: 10px; font-size: 8pt;"></div>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style scoped>
#data_table >>> tr:hover {
  /*background: transparent !important;*/
  cursor: default;
}

.nopadding {
  padding: 0px;
}
</style>

<script>
export default {
  name: "NewsCard",
  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
  },
  props: {
    title: undefined,
    subtitle: undefined,
    data_value: {
      default: "-",
    },
    data_table: [],
    back_color: {
      default: "#FFEA80",
    },
  },
  data: () => ({
    headers: [
      { text: "Date", align: "start", value: "t" },
      {
        text: "Headline",
        align: "start",
        value: "headline",
       
      },
      { text: '', value: 'data-table-expand' }
    ],
    expanded: [],
    sortBy: "t",
    sortDesc: true,
  }),
  methods: {
    handleClick(v) {
      window.open(
        "https://explorer.zephyrprotocol.com/block/" + v.bh,
        "_blank"
      );
    },
    shortenAddress(a) {
      return a.substr(0, 6) + "..." + a.substr(-6);
    },
  },
};
</script>
