<template>
  <div>
    <div
      v-if="liveStats && (loadingStatsData || loadingMinerData)"
      style="
        position: fixed;
        width: 100%;
        margin: 0px;
        bottom: 45px;
        left: 0px;
        max-height: 2px;
        z-index: 17;
      "
    >
      <v-progress-linear
        color="orange"
        style="height: 2px"
        indeterminate
      ></v-progress-linear>
    </div>
    <div
      v-if="!liveStats"
      style="position: fixed; top:0px; left; 0px; width: 100%; height: 100%; z-index: 999; display: flex; justify-content: center; align-items: center; flex-direction: column;"
    >
      <transition mode="out-in" name="fade">
        <div
          :key="loadingText"
          style="
            margin-top: -160px;
            margin-bottom: 0px;
            margin-left: -30px;
            color: gray;
          "
        >
          {{ loadingText }}
        </div>
      </transition>
      <div
        style="width: 100%; width: 260px; margin-top: 5px; margin-left: -30px"
      >
        <v-progress-linear
          color="orange"
          indeterminate
          style="height: 2px"
        ></v-progress-linear>
      </div>
    </div>
    <div v-if="liveStats" class="home">
      <v-row
        v-if="true"
        style="
          margin: 0px;
          padding: 0px;
          margin-top: -15px;
          margin-bottom: 25px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          margin-right: 23px;
          margin-left: 23px;
        "
      >
        <v-banner
          v-if="false"
          outlined
          dark
          elevation="6"
          rounded
          shaped
          style="background-color: #b23800; width: 100%; max-width: 1310px"
          >Before <span style="text-decoration: underline">February 1st</span>,
          all miners should point to
          <strong>zeph.thunderhash.com</strong> instead of
          zephyr.thunderhash.com as the latter will be deprecated and only valid
          for accessing the pool front-end. TCP ports remain the same and
          current block contribution and rewards are not affected whatsoever by
          this change. Please, update as soon as possible.
          <template v-slot:actions="{ dismiss }">
            <v-btn text dark @click="dismiss"> Dismiss </v-btn>
          </template>
        </v-banner>
      </v-row>
      <v-row
        style="
          margin: 0px;
          padding: 0px;
          margin-top: -28px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;         
          max-height: 80px;
        "
      >
      
      </v-row>
      <v-row
        style="
          margin-top: 5px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
        "
      >
      <BlocksChart
          :chart_data="blocks"
          style="display: flex; justify-content: center; max-height: 80px; max-width: 1314px; margin: 5px;"
        />
        <!-- back_color="#FFEA80" -->
        <StatCard
          v-bind:data_value="networkHashrate"
          :data_units="networkHashrateUnits"
          :data_table="networkInfo"
          title="Network"
          subtitle="Zephyr Protocol"
          back_color="#2F2F2F"
          card-image="zephyr-logo.png"
          :doTransition="false"
          style="width: 320px; height: 280px; margin: 5px;"
          v-bind:chart_data="networkHashrateChartData"
          tooltip-base-unit="H/s"
          tooltip-label="Hashrate"
          chart_color="#5DE5E130"
          :class="{'mobileWidth1': $vuetify.breakpoint.xsOnly, 'mobileWidth2': $vuetify.breakpoint.smOnly, 'defaultWidth': $vuetify.breakpoint.mdAndUp}"
        />

        <!-- back_color="#5DE5E1" -->
        <StatCard
          v-bind:data_value="poolHashrate"
          :data_units="poolHashrateUnits"
          :data_table="poolInfo"
          :doTransition="false"
          title="Pool"
          subtitle="ThunderHash"
          back_color="#2F2F2F"
          v-bind:chart_data="poolHashrateChartData"
          tooltip-base-unit="H/s"
          tooltip-label="Hashrate"
          style="min-width: 320px; height: 280px; margin: 5px"
          :class="{'mobileWidth1': $vuetify.breakpoint.xsOnly, 'mobileWidth2': $vuetify.breakpoint.smOnly, 'defaultWidth': $vuetify.breakpoint.mdAndUp}"
        />
        <!-- ack_color="#FFFFFF" -->
        <StatCard
          v-bind:data_value="soloHashrate"
          :data_units="soloHashrateUnits"
          :data_table="soloInfo"
          title="Solo"
          subtitle="ThunderHash"
          back_color="#2F2F2F"
          v-bind:chart_data="soloHashrateChartData"
          tooltip-base-unit="H/s"
          tooltip-label="Hashrate"
          style="min-width: 320px; height: 280px; margin: 5px"
          :class="{'mobileWidth1': $vuetify.breakpoint.xsOnly, 'mobileWidth2': $vuetify.breakpoint.smOnly, 'defaultWidth': $vuetify.breakpoint.mdAndUp}"
        />
        <!-- back_color="#FF6685" -->
        <DetailsCard
          :data_table="poolDetails"
          title="Pool Details"
          subtitle="Connection & Fees"
          titleBack="Top 5 Miners"
          subtitleBack="By Hashrate"
          back_color="#2F2F2F"
          chart_color="#5DE5E150"
          :number_colors="false"
          :back_table="top10Miners"
          card-image="flip-card-icon-white.png"
          style="min-width: 320px; height: 280px; margin: 5px"
          :class="{'mobileWidth1': $vuetify.breakpoint.xsOnly, 'mobileWidth2': $vuetify.breakpoint.smOnly, 'defaultWidth': $vuetify.breakpoint.mdAndUp}"
        />
        <StatCard
          v-bind:data_value="currentEffort"
          data_units="Current Effort"
          :data_table="poolBlocksInfo"
          title="Pool Blocks"
          subtitle="ThunderHash"
          back_color="#2F2F2F"
          style="min-width: 320px; height: 280px; margin: 5px"
          :class="{'mobileWidth1': $vuetify.breakpoint.xsOnly, 'mobileWidth2': $vuetify.breakpoint.smOnly, 'defaultWidth': $vuetify.breakpoint.mdAndUp}"
        />
        <StatCard
          v-bind:data_value="zephPrice"
          data_units="USD"
          :data_table="zephDataTable"
          v-bind:chart_data="zephChartData"
          title="ZEPH"
          subtitle="One Year"
          back_color="#2F2F2F"
          chart_color="#5DE5E150"
          number_colors="true"
          tooltip-base-unit="USD"
          tooltip-label="Price"
          style="min-width: 320px; height: 280px; margin: 5px"
          :class="{'mobileWidth1': $vuetify.breakpoint.xsOnly, 'mobileWidth2': $vuetify.breakpoint.smOnly, 'defaultWidth': $vuetify.breakpoint.mdAndUp}"
        />
        <StatCard
          v-if="!minerAddress"
          title="Miner"
          subtitle="<a href='#minerConfiguration'>Connect your miner</a>"
          style="min-width: 320px; height: 280px; margin: 5px"
          :class="{'mobileWidth1': $vuetify.breakpoint.xsOnly, 'mobileWidth2': $vuetify.breakpoint.smOnly, 'defaultWidth': $vuetify.breakpoint.mdAndUp}"
        />
        <StatCard
          v-if="minerAddress"
          v-bind:data_value="minerHashrate"
          :data_units="minerHashrateUnits"
          title="Miner"
          :subtitle="shortenAddress(minerAddress)"
          :data_table="minerAverages"
          back_color="#1F1F1F"
          v-bind:chart_data="minerChartData"
          tooltip-base-unit="H/s"
          tooltip-label="Hashrate"
          chart_color="rgb(255, 172, 28, 0.6)"
          style="min-width: 320px; height: 280px; margin: 5px"
          :class="{'mobileWidth1': $vuetify.breakpoint.xsOnly, 'mobileWidth2': $vuetify.breakpoint.smOnly, 'defaultWidth': $vuetify.breakpoint.mdAndUp}"
        />
        <StatCard
          v-if="!minerAddress"
          title="Payments"
          subtitle="<a href='#minerConfiguration'>Connect your miner</a>"
          style="min-width: 320px; height: 280px; margin: 5px"
          :class="{'mobileWidth1': $vuetify.breakpoint.xsOnly, 'mobileWidth2': $vuetify.breakpoint.smOnly, 'defaultWidth': $vuetify.breakpoint.mdAndUp}"
        />
        <StatCard
          v-if="minerAddress"
          v-bind:data_value="expectedIncome"
          :data_units="minerPendingBalanceUnits"
          title="Payments"
          :subtitle="shortenAddress(minerAddress)"
          :data_table="minerPaymentStats"
          back_color="#1F1F1F"
          :v-bind:chart_data="undefined"
          chart_type="bar"
          chart_color="rgb(125, 125, 125, 0.25)"
          style="min-width: 320px; height: 280px; margin: 5px"
          :class="{'mobileWidth1': $vuetify.breakpoint.xsOnly, 'mobileWidth2': $vuetify.breakpoint.smOnly, 'defaultWidth': $vuetify.breakpoint.mdAndUp}"
        />       
        <NewsCard
          v-if="liveStats"
          back_color="#2F2F2F"
          title="Pool News"
          subtitle="Thunderhash"
          :data_table="poolNews"
          style="margin: 5px; height: 360px; width: 100%; max-width: 652px"
        />
        <CalcCard
        title="Profit & Rewards"
        subtitle="Estimated Earnings"
        v-bind:difficulty="difficulty"
        :difficulty3d="difficulty3d"
        :difficulty7d="difficulty7d"
        :hashrate="minerHashrateNum"
        :block_reward="lastReward"
        :price="zephPrice"
        :priceBTC="zephBTCPrice"
        back_color="#1F1F1F"
        style="width: 100%; min-height: 360px; max-width: 652px; margin: 5px"        
        />
        <WorkersCard
          v-if="minerAddress && liveStats"
          back_color="#1F1F1F"
          title="Workers"
          :subtitle="shortenAddress(minerAddress)"
          :data_table="minerWorkers"
          style="margin: 5px; width: 100%; max-width: 1314px;"
        />
      </v-row>
      <v-row
        style="
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
        "
      >
        <NiceHashCard
          ref="nhChartCard"
          v-if="liveStats && nhData"
          back_color="#2F2F2F"
          title="NiceHash"
          subtitle="RandomX - 24 Hours"
          v-bind:data_value="nhCurrentHR"
          :data_units="nhCurrentHRUnits"
          :data_table="nhStats"
          style="width: 320px; height: 370px; margin: 5px"
          card-image="logo_nh.png"
          :chart_data="nhChartData"
          chart_type="line"
          chart_color="rgb(250, 171, 66, 0.8)"
          :class="{'mobileWidth1': $vuetify.breakpoint.xsOnly, 'mobileWidth2': $vuetify.breakpoint.smOnly, 'defaultWidth': $vuetify.breakpoint.mdAndUp}"
        />
        <NiceHashOrdersCard
          v-if="liveStats && nhData"
          back_color="#2F2F2F"
          title="NiceHash"
          subtitle="RandomX Orders"
          :data_table="nhDataOrderBook"
          style="margin: 5px; width: 100%; height: 370px; max-width: 984px"
          card-image="logo_nh.png"
        />

        <MRRRigsCard
          v-if="liveStats && MRRData"
          back_color="#2F2F2F"
          title="MRR"
          subtitle="Mining Rig Rentals"
          :data_table="MRRData.records"
          style="margin: 5px; width: 100%; max-width: 1314px"
          card-image="mrr.png"
        />
      
       
        <PaymentsCard
          v-if="minerAddress && liveStats"
          back_color="#1F1F1F"
          title="Miner Payments"
          :subtitle="shortenAddress(minerAddress)"
          :data_table="paymentsTable"
          style="margin: 5px; width: 100%; max-width: 1314px;"
        />
        <PaymentsCard
          v-if="minerAddress && liveStats"
          back_color="#2F2F2F"
          title="Pool Payments"
          subtitle="Thunderhash"
          :data_table="poolPaymentsTable"
          style="margin: 5px; width: 100%; max-width: 1314px;"
        />
        <PoolBlocksCard
          v-if="liveStats"
          back_color="#2F2F2F"
          title="Pool Blocks"
          subtitle="Thunderhash"
          :data_table="poolBlocksTable"
          style="margin-top: 150px; margin: 5px; width: 100%; max-width: 1314px;"
        />
       
      </v-row>

      <div
        style="padding-top: 50px; margin-top: -50px; visibility: hidden"
        id="minerConfiguration"
      ></div>
      <v-row
        v-if="!minerAddress"
        style="
          margin: 10px;
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
        "
      >
        <MinerSetupCard
          :api-url="API_URL"
          @updated-address="updatedMinerAddress"
          style="max-width: 1314px; margin-top: 5px;"
        />
      </v-row>

      <v-row
        v-if="minerAddress"
        style="
          margin-top: 20px;
          margin-bottom: 20px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
        "
      >
        <span style="color: gray; font-size: 12pt"
          >Miner data representative of address&nbsp;
        </span>
        <span style="color: gray; font-size: 12pt; font-style: italic"
          >{{ shortenAddress(minerAddress) }} &nbsp;</span
        ><v-btn
          style="margin-top: -2px"
          x-small
          elevation="2"
          @click="removeMinerAddress"
          >Clear</v-btn
        >
      </v-row>
    </div>
  </div>
</template>

<style scoped>
@import "~@flaticon/flaticon-uicons/css/all/all";

.home {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.mobileWidth1 {
  width: 100% !important;
}

.mobileWidth2 {
  width: 365px !important;
}

.defaultWidth {
  width: 320px !important;
}

</style>

<script>
import StatCard from "@/components/StatCard.vue";
import WorkersCard from "@/components/WorkersCard.vue";
import PaymentsCard from "@/components/PaymentsCard.vue";
import DetailsCard from "@/components/DetailsCard.vue";
import PoolBlocksCard from "@/components/PoolBlocksCard.vue";
import NewsCard from "@/components/NewsCard.vue";
import CalcCard from "@/components/CalcCard.vue";
import NiceHashCard from "@/components/NiceHashCard.vue";
import NiceHashOrdersCard from "@/components/NiceHashOrdersCard.vue";
import MRRRigsCard from "@/components/MRRRigsCard.vue";
import MinerSetupCard from "@/components/MinerSetupCard.vue";
import BlocksChart from "@/components/BlocksChart.vue";
import Numeral from "numeral";
import * as timeago from "timeago.js";

export default {
  name: "HomeView",
  components: {
    StatCard,
    WorkersCard,
    PaymentsCard,
    DetailsCard,
    PoolBlocksCard,
    NewsCard,
    NiceHashCard,
    NiceHashOrdersCard,
    MRRRigsCard,
    MinerSetupCard,
    BlocksChart,
    CalcCard
  },
  data: () => ({
    DATA_UPDATE_INTERVAL: 10000,
    UPDATE_TIMER: undefined,
    API_URL: "https://zephyr.thunderhash.com:8118/",
    CMC_URL_EXCHANGES: "cmc_zeph.json",
    NH_HR_HISTORY: "https://zephyr.thunderhash.com/nh_randomx.json",
    MRR_DATA: "./mrr_randomx.json",
    BLOCK_DATA: "https://zephyr.thunderhash.com/blocks.json",
    soloFee: 1,
    poolFee: 0.15,    
    poolNews: [
    {
        t: new Date("2024-03-10 20:41:45"),
        headline:
          '<div style="margin-top: 4px; margin-right: 10px;"><img style="width: 18px;" src="info.png"/></div><div style="margin-top: 5px;">Added Estimated Earnings Calculator</div>',
        text: `
      <p>We have added a estimated earnings calculator so ours miners can better understand what would be the expected profits of mining in the pool. By default it uses the current data which includes the miner hashrate, the current Zephyr Protocol network difficulty and the current ZEPH price versus the US dollar. By clicking on "Enable Custom Values" you can overwrite the current values with the desired ones. Please keep in mind that these are estimated rewards excluding hardware costs, electrical power costs, and fees (pool, network and transactions fees). Network difficulty, ZEPH price and hashrate can vary substantialy with time and turn a positive profit expectation into a very negative one.</p>      
      <p style="color: gray; font-style: italic;">- ThunderHash Team</p>
      `,
      },
    {
        t: new Date("2024-03-09 20:09:45"),
        headline:
          '<div style="margin-top: 4px; margin-right: 10px;"><img style="width: 16px;" src="warning-2.png"/></div><div style="margin-top: 5px;">Introducing the Dynamic Pool Fee System</div>',
        text: `
      <p>In our ongoing commitment to fairness and the decentralization of the Zephyr Protocol network, we have introduced a new pool fee system. This system takes into account the current hashrate of the pool. The aim is to attract more miners to the pool during periods of low hashrate and to incentivize miners to transition to another pool when ours has sufficient hashrate to consistently produce blocks.</p>
      <p>Effective immediately, the fee structure will be as follows:</p>
      <pre>
<strong>Pool Hashrate		Pool Fee</strong>
0 to 300 kH/s		0.05%  
300 to 500 kH/s		0.10%
0.5 to 1 MH/s		0.15%
1 to 5 MH/s			0.30%
5 to 10 MH/s		0.60%
10 to 20 MH/s		0.75%
20 to 100 MH/s		0.90%
100 to 200 MH/s		1.00%
200 to 500 MH/s		1.50%
More than 500 MH/s	2.00%        
      </pre>
      <p>We hope you understand this change and that it is beneficial both for our miners and for the decentralization of the Zephyr Protocol network. As always, any issues, concerns or suggestions, please drop by our <a href='https://discord.gg/YargZ9Xc6Y' target='_blank'>Discord</a> channel.</p>
      <p style="color: gray; font-style: italic;">- ThunderHash Team</p>
      `,
      },
    {
        t: new Date("2024-03-02 18:19:45"),
        headline:
          '<div style="margin-top: 2px; margin-right: 10px;"><img style="width: 18px;" src="info.png"/></div><div style="margin-top: 3px;">Pool PROP Block Fee Lowered to 0.15%</div>',
        text: `
      <p>We have lowered both the PROP fee to 0.15%. This is one of the lowest pool fees in the Zephyr mining space. Please, come mine with us and share the news with your fellow miners. Payment interval and minimum payout stay the same at 30 minutes and 0.1 ZEPH respectively.</p>
      <p style="color: gray; font-style: italic;">- ThunderHash Team</p>
      `,
      },
      {
        t: new Date("2024-01-26 11:19:45"),
        headline:
          '<div style="margin-top: 2px; margin-right: 10px;"><img style="width: 18px;" src="info.png"/></div><div style="margin-top: 3px;">Tor Network Available</div>',
        text: `
      <p><img style="width: 22px;" src="tor-logo.png"/> is now available for the front-end and pool TLS port 9999. You can now install Tor and point your miner to <span style="font-family: monospace; font-size: 6pt;">ewqzjeww73x4ocnuo7owmerpgzlzzcgxoyak56mci5mbiqqyvj5t5bad.onion</span> on TLS port 9999 for a more private mining. Please, go to <a href="https://xmrig.com/docs/miner/tor" target="_blank">Tor & SOCKS5</a> for instructions on how to connect your XMRig miner through the Tor Network.</p>
      <p style="color: gray; font-style: italic;">- ThunderHash Team</p>
      `,
      },
      {
        t: new Date("2024-01-11 17:21:03"),
        headline:
          '<div style="margin-top: 2px; margin-right: 10px;"><img style="width: 18px;" src="info.png"/></div><div style="margin-top: 3px;">Issue with Solo Pool Rewards Fixed</div>',
        text: `
      <p>An issue that was affecting the round contribution for prop pool has been identified and fixed today. Solo pool contributions were being added up to the prop pool which caused incorrect calculations in scores and round contribution. No rewards have been affected.</p>
      <p style="color: gray; font-style: italic;">- ThunderHash Team</p>
      `,
      },
      {
        t: new Date("2024-01-10 21:31:45"),
        headline:
          '<div style="margin-top: 2px; margin-right: 10px;"><img style="width: 16px;" src="warning-2.png"/></div><div style="margin-top: 3px;">Regarding the Reward Distribution Method</div>',
        text: `
      <p>Effective immediately, and to protect our miners, ThunderHash pool switches to a proportional score based reward distribution method. The reward will be now calculated based on the scores at the end of the round, giving preference to most recent shares. This will penalize abusers that only produce huge amounts of shares exclusively at the beginning of a round. We recommend this type of miners to switch to solo mining. With the updated reward distribution method, loyal miners that put regular work on the pool will be benefited and protected against <a href='https://bitcoin.stackexchange.com/questions/5072/what-is-pool-hopping' target='_blank'>pool hoppers</a> that try to take advantage of small pools and the work of modest miners. </p>
      <div style='display: flex; justify-content: center; gap:20px; flex-wrap: wrap; margin-bottom: 20px;'>
        <img style="width: 290px;" src="prop_table.png"/>
        <img style="width: 290px;" src="prop_chart.png"/>
      </div>     
      <p>The table and chart above show how the value of a 1M difficulty share decays with time. This disincentives miners who just contribute at the beginning of the block trying to get the most part of a block reward if they are lucky and benefit from the work of honest, continuous miners if they are not.</p>
      <p style="color: gray; font-style: italic;">- ThunderHash Team</p>
      `,
      },
    ],
    loadingText: "Rising up and unleashing the storm...",
    loadingStatsData: false,
    loadingMinerData: false,
    liveStats: false,
    lastReward: undefined,
    networkHashrate: undefined,
    networkHashrateUnits: undefined,
    networkHashrateChartData: [],
    networkInfo: undefined,
    poolHashrate: undefined,
    poolHashrateUnits: undefined,
    poolInfo: undefined,
    poolRoundHashes: undefined,
    poolRoundScore: undefined,
    poolPaymentsTable: undefined,
    poolBlocksTable: undefined,
    soloHashrate: undefined,
    soloHashrateUnits: undefined,
    soloInfo: undefined,
    minerAddress: undefined,
    minerHashrate: undefined,
    minerHashrateUnits: undefined,
    minerPendingBalance: undefined,
    minerPendingBalanceUnits: "Round Estimated",
    minerRoundHashes: undefined,
    minerRoundScore: undefined,
    minerRoundContribution: undefined,
    minerPaymentStats: undefined,
    expectedIncome: 0,
    paymentsChart: [],
    paymentsTable: [],
    poolBlocks: undefined,
    avgEffort: "-",
    poolBlocksInfo: undefined,
    minerAverages: undefined,
    minerWorkers: [],
    top10Miners: undefined,
    // Charts
    poolHashrateChartData: [],
    soloHashrateChartData: [],
    minerChartData: [],
    cmcData: undefined,
    zephPrice: undefined,
    zephBTCPrice: undefined,
    nhData: undefined,
    nhChartData: undefined,
    nhCurrentHR: undefined,
    nhCurrentHRUnits: undefined,
    nhStats: undefined,
    MRRData: undefined,
    blocks: [],
    difficulty: undefined,
    difficulty3d: undefined,
    difficulty7d: undefined,
    minerHashrateNum: 0,
    difficultyChart: []
  }),
  beforeRouteLeave(to, from, next) {
    clearInterval(this.UPDATE_TIMER);
    next();
  },
  created() {
    this.minerAddress = localStorage.getItem("minerAddress");

    setTimeout(() => {
      this.loadingText = "Maybe not... This is taking too long.";
    }, 15000);

    setTimeout(() => {
      this.loadingText = "Something's wrong, please try again later.";
    }, 20000);

    setTimeout(() => {
      this.loadingText = "Rising up and unleashing the storm...";
    }, 60000);

    this.getTop10Miners();
    this.getExchangeData();

    this.UPDATE_TIMER = setInterval(() => {
      //console.info("Updating...");
      this.getData();
    }, this.DATA_UPDATE_INTERVAL);
  },
  mounted() {
    this.$emit("hide-drawer", false);
    this.getData();
    if (this.minerAddress) {
      this.$emit("updated-address", this.minerAddress);
      this.getMinerData();
    }
  },
  methods: {
    updatedMinerAddress(a) {
      this.minerAddress = a;
      this.getData();
      this.getMinerData();
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.$nextTick(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    },
    removeMinerAddress() {
      localStorage.removeItem("minerAddress");
      this.minerAddress = undefined;
    },
    getBlockData() {
      fetch(this.BLOCK_DATA, { method: "GET" })
        .then((response) => response.json())
        .then((data) => {
          //console.info(data);

          for (let k = data.length - 1; k > 0; k--) {
            data[k]["bt"] = data[k]["t"] - data[k - 1]["t"];
          }
          this.blocks = data.slice(1);
          //console.log(this.blocks);
        });
    },
    getData() {
      this.loadingStatsData = true;
      var url = this.API_URL + "stats";
      if (this.minerAddress) url = url + "?address=" + this.minerAddress;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.liveStats = true;
          
          this.poolFee = data.config.fee;
          this.soloFee = data.config.soloFee;

          this.poolDetails = {
      '<i class="fi fi-rr-database" style="display: block;">&nbsp;Host</i>':
        "zeph.thunderhash.com",
      '<i class="fi fi-rr-tachometer-alt-slow" style="display: block;">&nbsp;Standard Port</i>':
        "5555",
      '<i class="fi fi-rr-cloud" style="display: block;">&nbsp;Cloud / Nicehash</i>':
        "6666",
      '<i class="fi fi-sr-shield" style="display: block;">&nbsp;TLS v1.3 Port</i>':
        "9999",
      '<i class="fi fi-rr-users">&nbsp;D-PROP/SOLO Fee</i>':
        this.poolFee + '% / ' + this.soloFee + '%',
      '<i class="fi fi-rr-dollar" style="display: block;">&nbsp;Mininum Payout</i>':
        "0.1 ZEPH",
      '<i class="fi fi-rr-clock" style="display: block;">&nbsp;Payment Interval</i>':
        "30 minutes",
    };

          let networkHashrate = this.formatNumber(
            data.network.difficulty / data.config.coinDifficultyTarget
          );
          this.networkHashrate = Numeral(networkHashrate[0]).format("0,0.00");
          this.networkHashrateUnits = networkHashrate[1] + "H/s";

          this.networkInfo = {
            Height: Numeral(data.network.height).format("0,0"),
            Difficulty: Numeral(data.network.difficulty).format("0,0"),
            "Last Reward":
              Numeral(data.lastblock.reward / 1e12).format("0,0.00") + " ZEPH",
          };

          this.lastReward = data.lastblock.reward / 1e12;

          let poolHashrate = this.formatNumber(data.pool.hashrate);
          this.poolHashrate = Numeral(poolHashrate[0]).format("0,0.00");
          this.poolHashrateUnits = poolHashrate[1] + "H/s";

          this.poolRoundHashes = data.pool.roundHashes;
          this.poolRoundScore = data.pool.roundScore;          

          this.poolInfo = {
            "Round Hashes": Numeral(data.pool.roundHashes).format("0,0"),
            "Pool Miners": Numeral(data.pool.miners).format("0,0"),
            "Pool Workers": Numeral(data.pool.workers).format("0,0"),
          };

          let soloHashrate = this.formatNumber(data.pool.hashrateSolo);
          this.soloHashrate = Numeral(soloHashrate[0]).format("0,0.00");
          this.soloHashrateUnits = soloHashrate[1] + "H/s";

          this.soloInfo = {
            "Solo Blocks": Numeral(data.pool.totalBlocksSolo).format("0,0"),
            "Solo Miners": Numeral(data.pool.minersSolo).format("0,0"),
            "Solo Workers": Numeral(data.pool.workersSolo).format("0,0"),
          };

          let minerHashrate = this.formatNumber(data.miner.hashrate);
          this.minerHashrate = Numeral(minerHashrate[0]).format("0,0.00");
          this.minerHashrateUnits = minerHashrate[1] + "H/s";

          this.poolBlocks = data.pool.totalBlocks;

          this.difficultyChart = data.charts.difficulty;
          this.difficulty = data.network.difficulty;
          
          // 1800 seconds per point (30min)
          // 3 days is 3*24*60 mins so 24*3*60 / 30 min so 144 points back
          let diff = []; 
          data.charts.difficulty.forEach( d => {
            diff.push(d[1]);
          });
          const _3day = diff.slice(-24);
          this.difficulty3d = Math.round(_3day.reduce((a,b) => a+b) / _3day.length);
          const _7day = diff.slice(-56);
          this.difficulty7d = Math.round(_7day.reduce((a,b) => a+b) / _7day.length);
          
          this.minerHashrateNum = data.miner.hashrate ? data.miner.hashrate : 0;

          this.currentEffort = Numeral(
            data.pool.roundHashes / this.difficulty7d 
          ).format("0,0.0%");

          let nextBlockTime =
            ((1 - data.pool.roundHashes / this.difficulty7d ) *
              this.difficulty7d ) /
            data.pool.hashrate;
          let blockSolveTime = this.getReadableTime(
            ((1 - data.pool.roundHashes / this.difficulty7d ) *
              this.difficulty7d ) /
              data.pool.hashrate
          );

          if (nextBlockTime <= 0) {
            blockSolveTime = "Overdue";
          }

          this.poolHashrateChartData = data.charts.hashrate;
          this.soloHashrateChartData = data.charts.hashrateSolo;
          
         
          let networkHashRateData = [];
          data.charts.difficulty.forEach((d) => {
            let a = [];
            a.push(d[0]);
            a.push(d[1] / 120);
            a.push(d[2]);
            networkHashRateData.push([d[0], Math.round(d[1] / 120), d[2]]);
          });

          this.networkHashrateChartData = networkHashRateData;

          // Pool payments table
          // Payments table
          this.poolPaymentsTable = [];
          for (let i = 0; i < data.pool.payments.length; i += 2) {
            let a = data.pool.payments[i].split(":");
            let t = new Date(1000 * data.pool.payments[i + 1]);
            this.poolPaymentsTable.push({
              t: t,
              h: a[0],
              z: Numeral(a[1] / 1e12).format("0,0.0000"),
              m: a[3],
            });
          }

          // Pool blocks table
          this.poolBlocksTable = [];
          let sumEffort = 0;
          let countBlocks = 0;

          for (let i = 0; i < data.pool.blocks.length; i += 2) {
            let a = data.pool.blocks[i].split(":");
            let e = a[5] / a[4];
            sumEffort += e;
            countBlocks++;
            let height = data.pool.blocks[i + 1];
            let t = new Date(1000 * a[3]);
            let difficulty = this.formatNumber(a[4]);
            this.poolBlocksTable.push({
              t: t,
              sp: a[0],
              a: a[1],
              bh: a[2],
              h: Numeral(height).format("0,0"),
              d: Numeral(difficulty[0]).format("0,0.0") + difficulty[1],
              e: a[5] / a[4],
              s: a[6],
              r: Numeral(a[7] / 1e12).format("0,0.00"),
              tg: data.network.height - height,
            });
          }

          this.avgEffort = Numeral(sumEffort / countBlocks).format("0,0.00%");

          this.poolBlocksInfo = {
            "Next Expected in": blockSolveTime,
            "Last Found": data.pool.stats
              ? timeago.format(data.pool.lastBlockFound)
              : "Never",
            "Total Found / Avg. Effort":
              this.poolBlocks + " / " + this.avgEffort,
          };

          if (this.minerAddress) {
            //console.log("retrieving data for: " + this.minerAddress);
            this.getMinerData();
          }

          this.getNiceHashData();
          this.getBlockData();
          this.getTop10Miners();
          this.getExchangeData();
          this.getMRRData();

          this.loadingStatsData = false;
        });
    },
    getMinerData() {
      this.loadingMinerData = true;
      var url = this.API_URL + "stats_address";
      if (this.minerAddress)
        url = url + "?address=" + this.minerAddress + "&longpoll=false";
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.minerStats = data;

          let minerPendingBalance = this.formatNumber(
            data.stats.balance * 1e-12
          );
          this.minerPendingBalance = Numeral(minerPendingBalance[0]).format(
            "0,0.[0000]"
          );

          this.minerRoundHashes = data.stats.roundHashes;
          this.minerRoundScore = data.stats.roundScore;
          
          if (!this.minerRoundScore) this.minerRoundScore = 0;
       
          this.minerRoundContribution =
            this.minerRoundHashes / this.poolRoundHashes;         

          this.minerRoundScoreContribution =
            this.minerRoundScore / this.poolRoundScore;
        
          //if (this.minerRoundScoreContribution < 0.001) this.minerRoundScoreContribution = 0;

          let expectedIncome = Numeral(
            (1 - 0.0085) *
              (1 - 0.065) *
              this.lastReward *
              this.minerRoundScoreContribution
          ).format("0.00[0]");
          //this.expectedIncome = ' ≈' + expectedIncome;
          this.expectedIncome = expectedIncome;

          let roundHashes = this.formatNumber(this.minerRoundHashes);
          //let roundScore = this.formatNumber(this.minerRoundScore);

          this.minerPaymentStats = {
            "Round Hashes / Score":
              Numeral(roundHashes[0]).format("0.0") +
              roundHashes[1] +
              " (" +
              Numeral(this.minerRoundContribution).format("0.00%") +
              " / " +
              Numeral(this.minerRoundScoreContribution).format("0.00%") +
              ")",
            "Pending Payment": this.minerPendingBalance + " ZEPH",
            "Total Paid":
              Numeral(data.stats.paid / 1e12).format("0,0.[000000]") + " ZEPH",
          };

          // Payments chart
          this.paymentsChart = [];
          for (let i = 0; i < data.payments.length; i += 2) {
            this.paymentsChart.push([
              data.payments[i + 1],
              data.payments[i].split(":")[1] / 1e12,
            ]);
          }

          // Payments table
          this.paymentsTable = [];
          for (let i = 0; i < data.payments.length; i += 2) {
            let a = data.payments[i].split(":");
            let t = new Date(1000 * data.payments[i + 1]);
            this.paymentsTable.push({
              t: t,
              h: a[0],
              z: Numeral(a[1] / 1e12).format("0,0.0000"),
              m: a[3],
            });
          }

          let avg1h = this.formatNumber(data.stats.hashrate_1h);
          let avg6h = this.formatNumber(data.stats.hashrate_6h);
          let avg24h = this.formatNumber(data.stats.hashrate_24h);
          this.minerAverages = {
            "Average 1 hour":
              Numeral(avg1h[0]).format("0,0.00") + " " + avg1h[1] + "H/s",
            "Average 6 hours":
              Numeral(avg6h[0]).format("0,0.00") + " " + avg6h[1] + "H/s",
            "Average 24 hours":
              Numeral(avg24h[0]).format("0,0.00") + " " + avg24h[1] + "H/s",
          };

          data.workers.forEach((a) => {
            let hr = this.formatNumber(a.hashrate);
            a.hashrate_display =
              Numeral(hr[0]).format("0,0.00") + " " + hr[1] + "H/s";

            let hr1 = this.formatNumber(a.hashrate_1h);
            a.hashrate_1h_display =
              Numeral(hr1[0]).format("0,0.00") + " " + hr1[1] + "H/s";

            let hr6 = this.formatNumber(a.hashrate_6h);
            a.hashrate_6h_display =
              Numeral(hr6[0]).format("0,0.00") + " " + hr6[1] + "H/s";

            let hr24 = this.formatNumber(a.hashrate_24h);
            a.hashrate_24h_display =
              Numeral(hr24[0]).format("0,0.00") + " " + hr24[1] + "H/s";

            a.lastShare_display = timeago.format(a.lastShare * 1000);

            let h = this.formatNumber(a.hashes);
            a.hashes_display = Numeral(h[0]).format("0,0.0") + " " + h[1];
            //a.hashes_display = Numeral(a.hashes).format("0,0");
          });

          this.minerWorkers = data.workers;

          /*let minerHashrateChartData = [];
          data.charts.hashrate.forEach( d => {
            minerHashrateChartData.push([d[0], d[1]]);
          });*/

          this.minerChartData = data.charts.hashrate;
          //this.$set(this.minerChartData, data.charts.hashrate);

          //console.log(this.minerChartData);

          //console.log("MINER DATA");
          //console.log(data);
          this.loadingMinerData = false;
        });
    },
    getMRRData() {
      /// Get RandomX Data

      var url = this.MRR_DATA;
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((data) => {
        this.MRRData = data;
        //console.log(data);
      });
    },

    getNiceHashData() {
      /// Get RandomX History
      this.loadingNH = true;

      var url = this.NH_HR_HISTORY;
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.nhData = data;
          this.loadingNH = false;
          this.nhChartData = this.nhData.randomx_history;
          let currentHR = this.formatNumber(
            this.nhChartData[this.nhChartData.length - 1][1]
          );
          this.nhCurrentHR = Numeral(currentHR[0]).format("0,0.00");
          this.nhCurrentHRUnits = currentHR[1] + "H/s";

          this.nhDataOrderBook = this.nhData.order_book;

          let hashrateVector = [];
          this.nhChartData.forEach((h) => {
            hashrateVector.push(h[1]);
          });

          let priceVector = [];
          this.nhChartData.forEach((h) => {
            priceVector.push(h[2]);
          });

          const avgHR = this.formatNumber(
            hashrateVector.reduce((a, b) => a + b, 0) / hashrateVector.length ||
              0
          );
          const maxHR = this.formatNumber(Math.max(...hashrateVector));
          const minHR = this.formatNumber(Math.min(...hashrateVector));

          const avgPX = this.formatNumber(
            priceVector.reduce((a, b) => a + b, 0) / priceVector.length || 0
          );
          const maxPX = this.formatNumber(Math.max(...priceVector));
          const minPX = this.formatNumber(Math.min(...priceVector));

          const HRData =
            Numeral(minHR[0]).format("0,0.0") +
            " / " +
            Numeral(maxHR[0]).format("0,0.0") +
            " / " +
            Numeral(avgHR[0]).format("0,0.0");

          const PXData =
            Numeral(10 * minPX[0]).format("0,0.0000") +
            " / " +
            Numeral(10 * maxPX[0]).format("0,0.0000") +
            " " +
            maxPX[1] +
            " / " +
            Numeral(10 * avgPX[0]).format("0,0.0000") +
            " " +
            avgPX[1];
          this.nhStats = {
            "Min/Max/Avg Hashrate": HRData,
            "Min/Max/Avg Price": PXData,
            "Optimal Price EU / USA":
              Numeral(this.nhData.optimal_prices["EU"]).format("0,0.0000") +
              " / " +
              Numeral(this.nhData.optimal_prices["USA"]).format("0,0.0000"),
            "Active Miners": Numeral(data.current.miners).format("0,0"),
            "Active Orders": Numeral(data.current.orders).format("0,0"),
            "Current Paying Price":
              Numeral(data.current.payingPrice).format("0,0.0000") +
              " BTC/GH/day",
          };
        })
        .catch((error) => {
          console.log("NH API Error: " + error);
        });
    },
    getTop10Miners() {
      var url = this.API_URL + "get_top10miners";

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          data.forEach((d) => {
            delete d.hashes;
            delete d.lastShare;
            let k = this.formatNumber(d["hashrate"]);
            d["hashrate"] = Numeral(k[0]).format("0,0.0") + " " + k[1] + "H/s";
          });

          this.top10Miners = data.slice(0, 5);
        });
    },
    getExchangeData() {
      //this.loadingData = true;
      fetch(this.CMC_URL_EXCHANGES, { method: "GET" })
        .then((response) => response.json())
        .then((data) => {
          data.r.price = Numeral(data.r.price).format("0,0.00");
          this.cmcData = data;
          this.zephPrice = data.r.price;          
          this.zephBTCPrice = data.r.priceBTC;
          this.zephChartData = data.c;
          this.zephDataTable = {
            "Volume 24 hours": Numeral(data.r.volume_24h).format("$0,0"),
            "Change 24 hours":
              Numeral(data.r.percent_change_24h).format("0,0.00") + "%",
            "Change 30 Days":
              Numeral(data.r.percent_change_30d).format("0,0.00") + "%",
          };

          //console.log(data.c);
          //this.loadingData = false;
        });
    },
    getReadableTime(seconds) {
      var units = [
        [60, "second"],
        [60, "minute"],
        [24, "hour"],
        [7, "day"],
        [4, "week"],
        [12, "month"],
        [1, "year"],
      ];

      function formatAmounts(amount, unit) {
        var rounded = Math.round(amount);
        unit = unit + (rounded > 1 ? "s" : "");
        //if (getTranslation(unit)) unit = getTranslation(unit);
        return "" + rounded + " " + unit;
      }

      var amount = seconds;
      for (var i = 0; i < units.length; i++) {
        if (amount < units[i][0]) {
          return formatAmounts(amount, units[i][1]);
        }
        amount = amount / units[i][0];
      }
      return formatAmounts(amount, units[units.length - 1][1]);
    },
    shortenAddress(a) {
      return a.substr(0, 6) + "..." + a.substr(-6);
    },
    formatNumber(n) {
      if (n < 1000) {
        return [Math.round(10000 * n) / 10000, " "];
      }
      var ranges = [
        { divider: 1e18, suffix: "E" },
        { divider: 1e15, suffix: "P" },
        { divider: 1e12, suffix: "T" },
        { divider: 1e9, suffix: "G" },
        { divider: 1e6, suffix: "M" },
        { divider: 1e3, suffix: "k" },
      ];
      for (var i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
          return [
            Math.round(10000 * (n / ranges[i].divider)) / 10000,
            ranges[i].suffix,
          ];
        }
      }
      return [Math.round(10000 * n) / 10000, " "];
    },
  },
};
</script>
