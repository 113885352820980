<template>
  <canvas
    v-if="chart_data"
    ref="chart"
    class="glass"
    style="
      display: flex;
      justify-content: center;
      border: 1px solid #292630;
      position: relative;
      z-index: 1;
      width: 100% !important; /*background-color: #0f0f0f;*/
      padding: 0px;
      margin: 0px;
      padding-left: 4px;
      padding-right: 4px;
    "
  ></canvas>
</template>

<style scoped>
.bringToTop {
  position: relative;
  z-index: 17;
}

.sendToBack {
  position: relative;
  z-index: 1;
}

#data_table >>> tr:hover {
  /*background: transparent !important;*/
  cursor: default;
}

.nopadding {
  padding: 0px;
}
</style>

<script>
import numeral from "numeral";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import "chartjs-adapter-moment";
import Numeral from "numeral";

export default {
  name: "BlocksChart",
  computed: {},
  props: {
    chart_data: undefined,
    chart_color: {
      default: "rgb(180, 240, 190, 0.5)",
    },
    number_colors: {
      default: false,
    },
    chart_type: {
      default: "bar",
    },
  },
  data: () => ({
    chart: undefined,
    labels: [],
    data_xy: [],
    data_line: [],
    ctx: undefined,
  }),
  watch: {
    chart_data: {
      deep: true,
      handler() {
        if (this.chart_data.length > 0) this.updateChartData();
      },
    },
  },
  created() {
    this.updateChartData();
  },
  mounted() {
    this.buildChart();
  },
  methods: {
    mouseover() {
      this.$refs["bigValue"].classList.value = "sendToBack";
    },
    mouseleave() {
      this.$refs["bigValue"].classList.value = "bringToTop";
    },
    updateChartData() {
      try {
        this.data_xy = [];
        this.data_line = [];
        this.chart_data.forEach((a) => {
          this.data_xy.push({
            //x: new Date(1000 * a.t),
            x: a.h,
            y: a.bt,
          });
          this.data_line.push({
            x: a.h,
            y: 120,
          });
        });

        if (this.$chart) {
          this.$chart.options.scales.x.min = this.data_xy[0].x;
          this.$chart.options.scales.x.max =
            this.data_xy[this.data_xy.length - 1].x;
          this.$chart.data.datasets[0].data = this.data_xy;
          this.$chart.data.datasets[1].data = this.data_line;
          this.$chart.update();
        }
      } catch (e) {
        console.error(e);
      }
    },
    buildChart() {
      const ctx = this.$refs.chart.getContext("2d");
      //let that = this;

      //var minX = this.data_xy[0].x;

      this.$chart = new Chart(ctx, {
        type: "bar",
        backgroundColor: "#FF0000",
        title: "Block Times",
        data: {
          datasets: [
            {
              label: "Block Time",
              data: this.data_xy,
              yAxisID: "y",
              borderColor: "transparent",
              //backgroundColor: '#223D88',
              backgroundColor: "#1B313A",
              //minBarThickness: 40,
              barThickness: "flex",
              //maxBarLength: 10,
              minBarLength: 2,
              /*fill: {
                target: "origin",
                above: this.chart_color, // Area will be red above the origin
                below: "rgb(0, 0, 255)", // And blue below the origin
              },
              pointRadius: 0,*/
              order: 1,
            },
            {
              label: "Theoretical",
              data: this.data_line,
              type: "line",
              order: 0,
              yAxisID: "y",
              borderColor: "#2f2f2f",
              backgroundColor: "#2f2f2f",
              pointRadius: 0,
              borderWidth: 1,
              borderDash: [5, 5],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 0,
            },
          },
          tooltips: {
            enabled: true,
            mode: "label",
          },
          interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
          },
          plugins: {
            legend: false,
            filler: {
              propagate: true,
            },
            title: {
              display: true,
              text: "Recent Block Times",
              color: "white",
              fullSize: false,
              font: {
                size: 12,
                weight: "normal",
              },
              padding: {
                top: 10,
                bottom: 0,
              },
            },
            subtitle: {
                display: true,
                text: 'Zephyr Protocol'
            },
            tooltip: {
              backgroundColor: "rgba(0,0,0,1)",
              zIndex: 9999999,
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";

                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    label += Numeral(context.parsed.y).format("0,0") + "s";
                    //label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                  }
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              label: "Height",
              display: false,
              offset: false,
              //type: "time",
              type: "linear",
              //min: minX,
              //suggestedMax: this.data_xy[this.data_xy.length - 1].x,
              /*time: {
                unit: "hour",
                paser: "HH:mm",
              },*/
              grid: {
                offset: true,
              },
              ticks: {
                //min: that.data_xy[0].x,
                beginAtZero: true,
                display: false,
              },
            },
            y: {
              type: "linear",
              display: false,
              position: "left",
              ticks: {
                font: {
                  size: 9,
                  family: "vazir",
                },
              },
            },
          },
        },
      });

      //console.log(this.$refs);
      //this.chart =  a;
      /*this.chart = new Chart(this.ctx, {
        type: "line",
        //labels: this.data_x,
        data: { datasaets: [
          {
            label: "My First Dataset",
            data: this.data_xy,
            fill: false,            
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
            yAxisID: 'y',
            xAxisID: 'x',
          },
        ]},

        options: {
          responsive: true,          
          scales: {
            x: 
              {
                ticks: {
                  fontColor: "white",
                  fontSize: "9",
                  autoSkip: true,
                  source: this.labels,
                  maxRotation: 0,
                  autoSkipPadding: 6,
                },
                type: "time",
                distribution: "series",
                offset: false,
                time: {
                  //unit: 'day',
                  displayFormats: {
                    hour: "h",
                    day: "D",
                    month: "MMM",
                  },
                },
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: "date",
                },
                gridLines: {
                  color: "#202020",
                },
              },
            y: 
              {
                
                ticks: {
                  beginAtZero: false,
                  fontColor: "white",
                  fontSize: "6",
                },
                display: true,
                position: "right",
                scaleLabel: {
                  display: false,
                  labelString: "price",
                },
                gridLines: {
                  color: "#202020",
                },
              },            
          },
        },
      });*/
    },
    handleClick(v) {
      window.open(
        "https://explorer.zephyrprotocol.com/block/" + v.bh,
        "_blank"
      );
    },
    shortenAddress(a) {
      return a.substr(0, 6) + "..." + a.substr(-6);
    },
    format4dps(l) {
      return numeral(l).format("0,0.0000");
    },
    formatMiners(m) {
      return numeral(m).format("0,0");
    },
    formatNumber(n) {
      if (n < 1000) {
        return [Math.round(10000 * n) / 100, " "];
      }
      var ranges = [
        { divider: 1e18, suffix: "E" },
        { divider: 1e15, suffix: "P" },
        { divider: 1e12, suffix: "T" },
        { divider: 1e9, suffix: "G" },
        { divider: 1e6, suffix: "M" },
        { divider: 1e3, suffix: "k" },
      ];
      for (var i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
          return [
            Math.round(100 * (n / ranges[i].divider)) / 100,
            ranges[i].suffix,
          ];
        }
      }
      return [Math.round(10000 * n) / 10000, " "];
    },
  },
};
</script>
